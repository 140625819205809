import * as Colors from "@stackchat/colors"
import { createGlobalStyle } from "styled-components"

const colorClasses = Object
  .keys(Colors)
  .filter(colorKey => colorKey !== "hexToRGB")
  .reduce(
    (reduction, colorKey) => {
      // @ts-ignore
      return reduction
        // @ts-ignore
        .concat(`.${ colorKey } { color: ${ Colors[colorKey] } !important; } `)
        // @ts-ignore
        .concat(`.${ colorKey }-bg { background-color: ${ Colors[colorKey] } !important; } `)
        // @ts-ignore
        .concat(`.${ colorKey }-border { border-color: ${ Colors[colorKey] } !important; } `)
    },
    ``
  )

export const GlobalColors = createGlobalStyle`
  ${ colorClasses }  
`