import { faBars, faTimes } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as React from "react"
import logoIcon from "../../assets/images/logo-icon.svg"
import logo from "../../assets/images/logo.svg"
import Image from "../image"
import { ITEMS } from "./items"
import { Container, HomeLink, IconContainer, List, MenuContainer } from "./styled"
import TopLevelItem from "./TopLevelItem"

interface HeaderState {
  showBackground: boolean,
  showMobileMenu: boolean
}

export default class Header extends React.Component<{ alwaysOnBackground?: boolean }, HeaderState> {
  state: HeaderState = {
    showBackground: this.props.alwaysOnBackground || false,
    showMobileMenu: false
  }

  componentDidMount() {
    if (!this.props.alwaysOnBackground) {
      window.addEventListener("scroll", this.showHeaderBackground)
      this.showHeaderBackground()
    }
  }

  componentWillUnmount() {
    if (!this.props.alwaysOnBackground) {
      window.removeEventListener("scroll", this.showHeaderBackground)
    }
  }

  showHeaderBackground = () => {
    if (!this.state.showBackground && window.pageYOffset > 48) {
      this.setState(prevState => ({
        showBackground: true
      }))
    }

    if (this.state.showBackground && window.pageYOffset < 48) {
      this.setState(prevState => ({
        showBackground: false
      }))
    }
  }

  toggleMobileMenu = () => {
    this.setState(prevState => ({
      showMobileMenu: !prevState.showMobileMenu
    }))
  }

  getMenu = (invertItems: boolean = false) => {
    return (
      <List style={{
        height: invertItems ? "auto" : "64px"
      }}>
        {ITEMS.map((item, index) => {
          return (
            <TopLevelItem
              key={index}
              item={item}
              invertButtons={invertItems || false}
              onFinish={invertItems ? this.toggleMobileMenu : () => null}
            />
          )
        })}
      </List>
    )
  }

  render() {
    const { showBackground, showMobileMenu } = this.state

    return (
      <>
        <Container className={`container-fluid ${ showBackground ? "scrolled" : "" }`}>
          <div className="container">
            <div className="row">
              {/* Logo */}
              <div className="col-6 col-lg-2 d-flex d-md-none d-lg-flex align-items-center">
                <HomeLink to="/"><Image src={logo} maxWidth="144px" /></HomeLink>
              </div>

              <div className="col-6 col-md-1 d-none d-md-flex d-lg-none align-items-center">
                <HomeLink to="/"><Image src={logoIcon} maxWidth="144px" /></HomeLink>
              </div>

              <div className="col-6 d-flex align-items-center justify-content-end d-md-none" style={{ height: 64 }}>
                <IconContainer onClick={this.toggleMobileMenu}>
                  <FontAwesomeIcon icon={showMobileMenu ? faTimes : faBars} color="#fff" style={{ fontSize: 20 }} />
                </IconContainer>
              </div>

              {/* Menu */}
              <MenuContainer className="d-none d-md-block col-md-11 col-lg-10">
                {this.getMenu()}
              </MenuContainer>
            </div>
          </div>
        </Container>

        {showMobileMenu ? (
          <MenuContainer className="d-md-none">
            {this.getMenu(true)}
          </MenuContainer>
        ) : null}
      </>
    )
  }
}
