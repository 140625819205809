import { N40 } from '@stackchat/colors';
import TextElements from 'components/new-design-system/text-elements';
import styled from 'styled-components';

export const BlogContent = styled.div`
  width: 100%;
  max-width: 48rem;
  margin: 0 auto;
  padding: 2rem;

  @media only screen and (max-width: 575.99px) {
    padding: 2rem 0;
  }
`;

export const RelatedArticles = styled.div`
  border-top: 1px solid ${ N40 };
  padding: 4rem 1rem;
  margin-top: 4rem;
  width: 100%;
`;

export const RelatedArticlesCarousel = styled.div`
  margin-top: 2rem;
  width: 100%;
  display: flex;
  align-items: stretch;
`;

export const KeepReadingTitle = styled(TextElements.h4)`
  font-weight: 600;
`;
