import { N0, N100 } from "@stackchat/colors"
import TextElements from "components/new-design-system/text-elements"
import { Link } from "gatsby"
import styled from "styled-components"

export const ArticleLink = styled(Link)`
  display: inline-flex;
  flex-shrink: 0;
  width: 30%;
  margin-right: 3rem;

  &:hover {
    text-decoration: none
  }
`

export const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 40px rgba(0,0,0,.1);
  transition-delay: 0s;
  transition-duration: 0.42s;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: 0;
  top: 0;
  left: 0;
  border-radius: 2px;
`

export const RelatedArticleContainer = styled.article`
  position: relative;
  flex-shrink: 0;
  width: 100%;
  padding: 1rem;
  background-color: ${ N0 };
  cursor: pointer;

  &:hover {
    ${ Background } {
      opacity: 1;
    }
  }
`

export const RelatedArticleHero = styled.div`
  width: 100%;
  padding-bottom: 100%;
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 2px;
`

export const ArticleDate = styled(TextElements.p)`
  margin-top: 2rem;
  font-size: 0.9rem;
  line-height: 1.35rem;
  color: ${ N100 };
`