import { MDXProvider } from "@mdx-js/react"
import Footer from "components/footer"
import DesignElements from "components/new-design-system"
import { locale } from "config/locale"
import * as React from "react"
import { IntlProvider } from "react-intl"
import Translations from "translations/index"

// react-intl now uses native translation APIs
// the if-statements below add polyfills in case
// some stuff doesn't exist in the browser
if (!Intl.PluralRules) {
  // tslint:disable-next-line: no-var-requires
  require("@formatjs/intl-pluralrules/polyfill")
  // tslint:disable-next-line: no-var-requires
  require("@formatjs/intl-pluralrules/dist/locale-data/en") // Add locale data for de
}

if (!Intl.RelativeTimeFormat) {
  // tslint:disable-next-line: no-var-requires
  require("@formatjs/intl-relativetimeformat/polyfill")
  // tslint:disable-next-line: no-var-requires
  require("@formatjs/intl-relativetimeformat/dist/locale-data/en") // Add locale data for de
}

export const setup = (Component: React.ComponentType<any>) => {
  const ComponentReadyToGo = (componentProps: any) => (
    <IntlProvider locale={locale} messages={Translations[locale]}>
      <MDXProvider
        components={DesignElements}>
        <Component {...componentProps} />

        <Footer />
      </MDXProvider>
    </IntlProvider>
  )

  return ComponentReadyToGo
}
