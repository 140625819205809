export enum Locale {
  English = "en",
  Chinese = "zh"
}

enum Country {
  Australia = "au",
  China = "cn"
}

const locales = {
  [Country.Australia]: Locale.English,
  [Country.China]: Locale.Chinese
}

const country: Country = "au"

export const locale = locales[country]