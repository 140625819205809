import HubspotForm from "components/hubspot-form"
import TextElements from "components/new-design-system/text-elements"
import * as React from "react"
import { Child, ContactAndSubscribeContainer, FootnoteLink } from "./styled"

const regex = /\[[a-zA-Z0-9]+[a-zA-Z0-9 ]*\]\(\/[a-zA-Z0-9]+[a-zA-Z0-9 /\\-]+\)/g

export default function ContactAndSubscribe({ footnote }: { footnote?: string }) {
  if (!footnote) {
    return (
      <ContactAndSubscribeContainer>
        <Child>
          <HubspotForm
            portalId="3371700"
            formId="9b8a3d5c-0ca2-415a-9403-d8de927e237e"
            id="top"
            light={false}
            padded={true}
          />
        </Child>
      </ContactAndSubscribeContainer>
    )
  }


  let formattedFootnote: React.ReactNode[] = [footnote]
  const matches = footnote.match(regex)
  if (matches) {
    matches.forEach(match => {
      if (formattedFootnote.length === 0) {
        formattedFootnote = footnote.split(match)
      } else {
        const [firstPart, lastPart] = (formattedFootnote[formattedFootnote.length - 1] as string)
          .split(match)

        const [text, link] = match
          .replace("[", "")
          .replace(")", "")
          .split("](/")

        const formattedPart = (
          <FootnoteLink to={link}>
            {text}
          </FootnoteLink>
        )

        formattedFootnote = [
          ...formattedFootnote.slice(0, formattedFootnote.length - 1),
          firstPart,
          formattedPart,
          lastPart
        ]
      }
    })
  }

  return (
    <ContactAndSubscribeContainer>
      <Child>
        <HubspotForm
          portalId="3371700"
          formId="9b8a3d5c-0ca2-415a-9403-d8de927e237e"
          id="top"
          light={false}
          padded={true}
        />
      </Child>

      <Child>
        <TextElements.p>
          {
            formattedFootnote.map(
              (formattedFootnotePart, index) => {
                if (React.isValidElement(formattedFootnotePart)) {
                  return React.cloneElement(
                    formattedFootnotePart, { key: index }
                  )
                }

                return <span key={index}>{formattedFootnotePart}</span>
              }
            )
          }
        </TextElements.p>
      </Child>
    </ContactAndSubscribeContainer>
  )
}