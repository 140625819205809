// ILLUSTRATIONS
/*
  "home.engage.illustration.voicesearch": "Voice Search",
  "home.engage.illustration.conversationalcommerce": "Conversational Commerce",
  "home.engage.illustration.servicedelivery": "Service Delivery",
  "home.engage.illustration.socialcampaigns": "Social Campaigns",
*/

// HUBSPOT FORMS
// These values are now managed via the Hubspot dashboard
// https://app.hubspot.com/forms/3371700/editor/05686a2c-7f73-4adf-ab01-750dcbcf06be/edit/form
/*
  "contact.form.firstname.label": "First name",
  "contact.form.firstname.placeholder": "Your first name",
  "contact.form.lastname.label": "Last name",
  "contact.form.lastname.placeholder": "Your last name",
  "contact.form.email.label": "Work email",
  "contact.form.email.placeholder": "you@yourcompany.com",
  "contact.form.jobtitle.label": "Your role",
  "contact.form.jobtitle.founder": "founder",
  "contact.form.jobtitle.executive": "executive",
  "contact.form.jobtitle.productmanager": "product manager",
  "contact.form.jobtitle.sales": "sales",
  "contact.form.jobtitle.marketing": "marketing",
  "contact.form.jobtitle.developer": "developer",
  "contact.form.jobtitle.customersuccessmanager": "customer success manager",
  "contact.form.phone.label": "Phone number",
  "contact.form.phone.placeholder": "Your phone number",
  "contact.form.thankyou": "Thanks, we'll be in touch soon!",
*/

export const en: Record<string, string> = {
  // Header
  "header.platform.title": "Platform",
  "header.resources.title": "Resources",
  "header.resources.items.item1": "Documentation",
  "header.resources.items.item2": "Blog",
  "header.pricing.title": "Pricing",
  "header.enterprise.title": "Enterprise",
  "header.demo.title": "Get a Demo",
  "header.signup.title": "Sign up Free",
  "header.signin.title": "Sign in",

  // Footer
  "footer.product.title": "Product",
  "footer.product.bot-builder": "Bot Builder",
  "footer.product.pricing": "Pricing",
  "footer.product.changelog": "Changelog",
  "footer.learn.title": "Learn",
  "footer.learn.blog": "Blog",
  "footer.learn.docs": "Docs",
  "footer.company.title": "Company",
  "footer.company.contact": "Contact",
  "footer.terms": "Terms of Service",
  "footer.privacy": "Privacy Policy",

  // Registration Form
  "registration-form.button-title": "Create Free Account",

  // Home Page
  "home.hero.heading": "Drive business through amazing chat experiences",
  "home.hero.description1": "Attract customers. Boost engagement. Maximise retention.",
  "home.hero.description2": "The world's leading solution for AI-driven, omnichannel chat and voice automation.",
  "home.hero.link": "Get Stackchat free",
  "home.hero.cta.heading": "Conversational Marketing Just Got Easy",
  "home.hero.cta.description": "See what a Stackchat voice app could look like on your website.",
  "home.attract.heading": "Attract",
  "home.attract.description": "Connect with your future customers in just a few clicks using our prebuilt chatbots.",
  "home.attract.link": "See all prebuilt chatbots",
  "home.engage.heading": "Engage",
  "home.engage.description": "Drive customer engagement by automating and personalising your customer conversations at scale.",
  "home.engage.link": "See product demos",
  "home.retain.heading": "Retain",
  "home.retain.description": "Go from arms-length relationship with customers to 24/7 interaction using blended AI and human chat.",
  "home.retain.link": "Learn about 24/7 support",
  "home.connect.heading": "Connect",
  "home.connect.description": "Stackchat connects your business to any messaging channel, unifying each customer chat into an omnichannel conversation with a single view of your customer.",
  "home.connect.link": "See all channels",
  "home.enterprise.heading": "Enterprise-grade scale and security.",
  "home.enterprise.description": "Get chatbots for the enterprise and protect your customer data with our battle-tested GDPR-compliant platform.",
  "home.enterprise.link": "Read more",
  "home.features.heading": "Start building now",
  "home.features.description": "Stackchat is your all-in-one conversational marketing platform to build, manage and optimize chatbots for your business.",
  "home.features.link": "Get Stackchat free",
  "home.features.card1.title": "NLP AI & Context",
  "home.features.card1.description": "Allow any member of your organization, regardless of background, to design and deploy AI-driven conversational experiences for your customers.",
  "home.features.card1.link": "Read more",
  "home.features.card2.title": "Embedded Voice",
  "home.features.card2.description": "Use our proven voice AI technologies on both mobile and web and customise models to detect your brand's special vocabulary.",
  "home.features.card2.link": "Read more",
  "home.features.card3.title": "Bot Builder",
  "home.features.card3.description": "Intuitive, easy-to-use and best-in-class, the Stackchat Studio bot builder gives you the power and flexibility to build any chatbot.",
  "home.features.card3.link": "Read more",
  "home.features.card4.title": "Logs & Analytics",
  "home.features.card4.description": "Stackchat's built-in analytics suite allows you to see exactly how users are interacting with your bot.",
  "home.features.card4.link": "Read more",
  "home.subscribe.heading": "Subscribe",
  "home.subscribe.description": "Receive updates about industry trends, new features, tutorials and more.",
  "home.industry.heading": "Scale growth at your business.",
  "home.industry.description": "Nearly 70% of consumer inquiries can be automated. Learn how to apply conversational marketing in your industry.",
  "home.industry.card1.title": "Tourism & Hospitality",
  "home.industry.card1.description": "Bots can browse flights, hotel rooms & car rentals, retrieve pricing information, provide discount codes and answer FAQs.",
  "home.industry.card1.link": "Read more",
  "home.industry.card2.title": "Higher Education",
  "home.industry.card2.description": "Bots can provide library, gym and laboratory hours, class registration deadlines & financial aid applications amongst many other applications.",
  "home.industry.card2.link": "Read more",
  "home.industry.card3.title": "Finance",
  "home.industry.card3.description": "Bots can help process payments, show interest rate comparisons and check the backend status on card applications amongst many other use cases.",
  "home.industry.card3.link": "Read more",
  "home.industry.card4.title": "Retail & eCommerce",
  "home.industry.card4.description": "Bots can help retrieve pricing information, product specifications & availability, highlight promotional offers and provide shipping & installation information and more.",
  "home.industry.card4.link": "Read more",
  "home.industry.link1": "Contact sales",
  "home.industry.link2": "Pricing",
  "home.channels.link": "See all channels",
  "home.cta2.heading": "Ready to get started?",
  "home.cta2.description": "Sign up for a free account and start building your bot today!",
  "home.cta2.link1": "Contact sales",
  "home.cta2.link2": "Sign up free",

  // Pricing Page
  "pricing.billed-monthly": "per month billed monthly",
  "pricing.billed-annually": "per month billed annually",
  "pricing.section1.heading": "Flexible Plans, Simple Pricing",
  "pricing.section1.description1": "Start building today with our free plan to experience Stackchat.",
  "pricing.section1.description2": "No commitment. No credit card required.",
  "pricing.tab1.title": "For Individuals",
  "pricing.tab1.column1.title": "Free",
  "pricing.tab1.column1.description": "All the basics for beginning work with chatbots.",
  "pricing.tab1.column1.price": "$0",
  "pricing.tab1.column1.link": "Sign up free",
  "pricing.tab1.column1.list.item1": "2 chatbots",
  "pricing.tab1.column1.list.item2": "Web messenger integration",
  "pricing.tab1.column1.list.item3": "1 additional integration",
  "pricing.tab1.column1.list.item4": "50 conversations per month",
  "pricing.tab1.column1.list.item5": "125 speech-to-text messages",
  "pricing.tab1.column2.title": "Starter",
  "pricing.tab1.column2.description": "Ideal for those beginning their first real chatbot project.",
  "pricing.tab1.column2.link": "Contact sales",
  "pricing.tab1.column2.list.item1": "2 chatbots",
  "pricing.tab1.column2.list.item2": "Web messenger integration",
  "pricing.tab1.column2.list.item3": "3 additional integrations",
  "pricing.tab1.column2.list.item4": "500 conversations per month",
  "pricing.tab1.column2.list.item5": "1,250 speech-to-text messages",
  "pricing.tab1.column2.price": "$150",
  "pricing.tab2.title": "For Teams",
  "pricing.tab2.column1.title": "Growth",
  "pricing.tab2.column1.description": "For those ready to expand their chatbot's audience.",
  "pricing.tab2.column1.price": "$500",
  "pricing.tab2.column1.link": "Contact sales",
  "pricing.tab2.column1.list.item1": "3 chatbots",
  "pricing.tab2.column1.list.item2": "Web messenger integration",
  "pricing.tab2.column1.list.item3": "3 additional integrations",
  "pricing.tab2.column1.list.item4": "5000 conversations per month",
  "pricing.tab2.column1.list.item5": "12,500 speech-to-text messages",
  "pricing.tab2.column2.title": "Premium",
  "pricing.tab2.column2.description": "Ideal for companies ready to scale their chat strategy.",
  "pricing.tab2.column2.price": "$1,999",
  "pricing.tab2.column2.link": "Contact sales",
  "pricing.tab2.column2.list.item1": "5 chatbots",
  "pricing.tab2.column2.list.item2": "Web messenger integration",
  "pricing.tab2.column2.list.item3": "Unlimited integrations",
  "pricing.tab2.column2.list.item4": "50,000 conversations per month",
  "pricing.tab2.column2.list.item5": "125,000 speech-to-text messages",
  "pricing.tab3.title": "For Enterprise",
  "pricing.tab3.column.title": "For Enterprise",
  "pricing.tab3.column.description": "Ideal for organizations with > 1K employees, high traffic sites, or ready-to-roll-out Conversational Experience Management across multiple teams.",
  "pricing.tab3.column.link": "Contact sales",
  "pricing.tab3.column.list.item1": "12 week onboarding which includes technical integration setup, Adobe Experience Cloud integration, and training for your entire team.",
  "pricing.tab3.column.list.item2": "On-premise deployment, hybrid cloud, or dedicated private AWS account infrastructure.",
  "pricing.tab3.column.list.item3": "Dedicated professional services team to create your AI models and design and deliver your intelligent assistant.",
  "pricing.tab3.column.list.item4": "Custom data retention, compliance and auditing policies.",
  "pricing.section2.heading": "Ready to see how a Stackchat voice experience can unlock new customers?",
  "pricing.section2.link": "Contact sales",
  "pricing.section2.main-link": "See Full Comparison",
  "pricing.section3.heading": "Full Plan Comparison",
  "pricing.section3.table.column1.pricing": "Pricing",
  "pricing.section3.table.column1.term": "Term",
  "pricing.section3.table.column1.availableBots": "Available Bots",
  "pricing.section3.table.column1.additionalPrice": "Price Per Additional Bot",
  "pricing.section3.table.column1.monthlyConversations": "Monthly Conversations",
  "pricing.section3.table.column1.pricePerConversation": "Price Per Extra Conversation",
  "pricing.section3.table.column1.speechToTextMessages": "Monthly Speech-to-Text Messages (STM)",
  "pricing.section3.table.column1.pricePerSTM": "Price Per Extra STM",
  "pricing.section3.table.column1.volumeDiscounts": "Volume Discounts",
  "pricing.section3.table.column1.integrationsIncluded": "Integrations Included",
  "pricing.section3.table.column1.speechToText": "Speech-to-Text Capabilities",
  "pricing.section3.table.column1.supportPlan": "Support Plan",
  "pricing.section3.table.column1.deployment": "Deployment",
  "pricing.section3.table.column1.studioUsers": "Stackchat Studio Users",
  "pricing.section3.table.column1.strategyAndRoadmap": "Conversational Strategy and Roadmap",
  "pricing.section3.table.column1.planningAndExecution": "Planning, Execution & Maintenance",
  "pricing.section3.table.column2.title": "Free",
  "pricing.section3.table.column2.description": "Everything you need to give Stackchat a test drive",
  "pricing.section3.table.column2.link": "Try it free",
  "pricing.section3.table.column2.pricing": "/mo",
  "pricing.section3.table.column2.price": "$0",
  "pricing.section3.table.column2.term": "---",
  "pricing.section3.table.column2.availableBots": "1",
  "pricing.section3.table.column2.additionalPrice": "---",
  "pricing.section3.table.column2.monthlyConversations": "50",
  "pricing.section3.table.column2.pricePerConversation": "---",
  "pricing.section3.table.column2.speechToTextMessages": "125",
  "pricing.section3.table.column2.pricePerSTM": "---",
  "pricing.section3.table.column2.volumeDiscounts": "---",
  "pricing.section3.table.column2.integrationsIncluded": "Web Messenger and 1 additional integration",
  "pricing.section3.table.column2.supportPlan": "Basic",
  "pricing.section3.table.column2.deployment": "Multi-tenancy hosting",
  "pricing.section3.table.column2.studioUsers": "1",
  "pricing.section3.table.column2.strategyAndRoadmap": "---",
  "pricing.section3.table.column2.planningAndExecution": "---",
  "pricing.section3.table.column3.title": "Starter",
  "pricing.section3.table.column3.description": "All the necessities for starting a new conversational project",
  "pricing.section3.table.column3.link": "Contact us",
  "pricing.section3.table.column3.pricing": "/mo",
  "pricing.section3.table.column3.price": "$150",
  "pricing.section3.table.column3.term": "Monthly",
  "pricing.section3.table.column3.availableBots": "2",
  "pricing.section3.table.column3.additionalPrice": "---",
  "pricing.section3.table.column3.monthlyConversations": "500",
  "pricing.section3.table.column3.pricePerConversation": "---",
  "pricing.section3.table.column3.speechToTextMessages": "1,250",
  "pricing.section3.table.column3.pricePerSTM": "---",
  "pricing.section3.table.column3.volumeDiscounts": "---",
  "pricing.section3.table.column3.integrationsIncluded": "Web Messenger and 3 additional integrations",
  "pricing.section3.table.column3.supportPlan": "Basic",
  "pricing.section3.table.column3.deployment": "Multi-tenancy hosting",
  "pricing.section3.table.column3.studioUsers": "2",
  "pricing.section3.table.column3.strategyAndRoadmap": "---",
  "pricing.section3.table.column3.planningAndExecution": "---",
  "pricing.section3.table.column4.title": "Growth",
  "pricing.section3.table.column4.description": "Scale and flexibility for products to reach more users",
  "pricing.section3.table.column4.link": "Contact us",
  "pricing.section3.table.column4.pricing": "/mo",
  "pricing.section3.table.column4.price": "$500",
  "pricing.section3.table.column4.term": "Monthly",
  "pricing.section3.table.column4.availableBots": "3",
  "pricing.section3.table.column4.additionalPrice": "$50",
  "pricing.section3.table.column4.monthlyConversations": "5,000",
  "pricing.section3.table.column4.pricePerConversation": "$0.05",
  "pricing.section3.table.column4.speechToTextMessages": "12,500",
  "pricing.section3.table.column4.pricePerSTM": "$0.05",
  "pricing.section3.table.column4.volumeDiscounts": "---",
  "pricing.section3.table.column4.integrationsIncluded": "Web Messenger and 3 additional integrations",
  "pricing.section3.table.column4.supportPlan": "Basic",
  "pricing.section3.table.column4.deployment": "Multi-tenancy hosting",
  "pricing.section3.table.column4.studioUsers": "5",
  "pricing.section3.table.column4.strategyAndRoadmap": "---",
  "pricing.section3.table.column4.planningAndExecution": "---",
  "pricing.section3.table.column5.title": "Premium",
  "pricing.section3.table.column5.description": "Scale and support for large products with many users",
  "pricing.section3.table.column5.link": "Contact us",
  "pricing.section3.table.column5.pricing": "/mo",
  "pricing.section3.table.column5.price": "$1,999",
  "pricing.section3.table.column5.term": "Annually",
  "pricing.section3.table.column5.availableBots": "5",
  "pricing.section3.table.column5.additionalPrice": "$50",
  "pricing.section3.table.column5.monthlyConversations": "50,000",
  "pricing.section3.table.column5.pricePerConversation": "$0.04",
  "pricing.section3.table.column5.speechToTextMessages": "125,000",
  "pricing.section3.table.column5.pricePerSTM": "$0.04",
  "pricing.section3.table.column5.volumeDiscounts": "---",
  "pricing.section3.table.column5.integrationsIncluded": "Web Messenger and unlimited additional integrations",
  "pricing.section3.table.column5.supportPlan": "Standard",
  "pricing.section3.table.column5.deployment": "Multi-tenancy hosting",
  "pricing.section3.table.column5.studioUsers": "10",
  "pricing.section3.table.column5.planningAndExecution": "---",
  "pricing.section3.table.column6.title": "Enterprise",
  "pricing.section3.table.column6.description": "Everything needed to run enterprise products at scale",
  "pricing.section3.table.column6.link": "Contact us",
  "pricing.section3.table.column6.pricing": "Contact Us",
  "pricing.section3.table.column6.term": "Annually",
  "pricing.section3.table.column6.availableBots": "Unlimited",
  "pricing.section3.table.column6.additionalPrice": "---",
  "pricing.section3.table.column6.monthlyConversations": "100,000",
  "pricing.section3.table.column6.pricePerConversation": "$0.04",
  "pricing.section3.table.column6.speechToTextMessages": "250,000",
  "pricing.section3.table.column6.pricePerSTM": "$0.04",
  "pricing.section3.table.column6.volumeDiscounts": "Available",
  "pricing.section3.table.column6.integrationsIncluded": "Web Messenger and unlimited additional integrations",
  "pricing.section3.table.column6.supportPlan": "Platinum",
  "pricing.section3.table.column6.deployment": "Dedicated infrastructure in an isolated AWS instance or on-premises.",
  "pricing.section3.table.column6.studioUsers": "Need based",
  "pricing.section3.table.separator.chatbot-volume": "Chatbot Volume and Rates",
  "pricing.section3.table.separator.volume-rates": "Conversation Rates and Volume",
  "pricing.section3.table.separator.channel-integrations": "Channel Integrations",
  "pricing.section3.table.separator.support-levels": "Support Levels",
  "pricing.section3.table.separator.features": "Features",
  "pricing.section3.table.separator.professional-services": "Professional Services",
  "pricing.section3.tooltip.integrations": "An integration is a connection to one of the third-party services we support for Natural Language Processing and Analytics.",
  "pricing.section3.tooltip.conversations": "A conversation is up to 50 messages exchanged between a unique end-user and your bot in a 24 hour period.",
  "pricing.section3.tooltip.support": "For the details of our support plans, please see the table at the bottom of the page.",
  "pricing.section4.heading": "Ready to request a demo of Stackchat Studio?",
  "pricing.section4.link": "Contact sales",
  "pricing.section5.heading": "Support Plan Comparison",
  "pricing.section5.table.column1.comms": "Communication Channels",
  "pricing.section5.table.column1.supportHours": "Support Hours",
  "pricing.section5.table.column1.namedPersonel": "Named Personnel",
  "pricing.section5.table.column1.generalIssues": "General Issues",
  "pricing.section5.table.column1.degradedService": "Degraded Service",
  "pricing.section5.table.column1.downtime": "Production Downtime",
  "pricing.section5.table.column1.uptime": "Guaranteed Uptime",
  "pricing.section5.column2.title": "Basic",
  "pricing.section5.column2.description": "Capable support for small businesses on the Free, Starter & Growth Plans",
  "pricing.section5.table.column2.comms": "Email, Github & Slack",
  "pricing.section5.table.column2.supportHours": "Not guaranteed",
  "pricing.section5.table.column2.namedPersonel": "---",
  "pricing.section5.table.column2.generalIssues": "Best effort",
  "pricing.section5.table.column2.degradedService": "Best effort",
  "pricing.section5.table.column2.downtime": "Best effort",
  "pricing.section5.table.column2.uptime": "---",
  "pricing.section5.column3.title": "Standard",
  "pricing.section5.column3.description": "Expanded support with dedicated hours for Premium users",
  "pricing.section5.table.column3.comms": "Email, Github & Slack",
  "pricing.section5.table.column3.supportHours": "Weekdays 9AM-8PM AEST",
  "pricing.section5.table.column3.generalIssues": "Under 8 business hours",
  "pricing.section5.table.column3.degradedService": "Under 4 business hours",
  "pricing.section5.table.column3.downtime": "Under 2 business hours",
  "pricing.section5.table.column3.uptime": "---",
  "pricing.section5.column4.title": "Platinum",
  "pricing.section5.column4.description": "All-encompassing support for the Enterprise",
  "pricing.section5.table.column4.comms": "Phone, Email, Github & Slack",
  "pricing.section5.table.column4.supportHours": "24/7",
  "pricing.section5.table.column4.generalIssues": "Under 4 business hours",
  "pricing.section5.table.column4.degradedService": "Under 2 business hours",
  "pricing.section5.table.column4.downtime": "Under 1 business hours",
  "pricing.section5.table.column4.uptime": "99.95%",
  "pricing.section5.table.separator.response": "Response Times",
  "pricing.section5.table.separator.sla": "Service Level Agreement (SLA)",
  "pricing.section6.heading": "Like what you've seen? Sign up for a free account to see Stackchat in action.",
  "pricing.section6.link": "Sign up free",
  "pricing.section7.heading": "Great Stackchat Features Included in Every Package",
  "pricing.section7.description": "Across all of our packages, we give you the power to create compelling conversational experiences for your users.",
  "pricing.section7.feature1.title": "Stackchat Studio",
  "pricing.section7.feature1.description": "Intuitive, easy-to-use and top-of-its-class, the Stackchat Studio bot builder gives you the power and flexibility to build any chatbot.",
  "pricing.section7.feature2.title": "Data Security Regulation Compliance",
  "pricing.section7.feature2.description": "Stackchat is fully compliant with GDPR and China's Cyber Security Law, enabling you to confidently roll out chatbots around the world.",
  "pricing.section7.feature3.title": "CRM & User Authentication",
  "pricing.section7.feature3.description": "Every conversation between your bot and a user results in a new record in the Stackchat CRM system, even across different channels.",
  "pricing.section7.feature4.title": "Social Channels",
  "pricing.section7.feature4.description": "Publish your bot to Facebook Messenger and WeChat Official Accounts and Mini Programs to meet your users on the world's most popular platforms.",
  "pricing.section7.feature5.title": "Analytics",
  "pricing.section7.feature5.description": "Stackchat's full built-in analytics suite allows you to see exactly how users are interacting with your bot, or use our Adobe Analytics support for a single source of truth.",
  "pricing.section7.feature6.title": "Detailed Logs",
  "pricing.section7.feature6.description": "Something not working as intended in your bot? Directly access the logs to pinpoint exactly where the problem is occuring.",
  "pricing.section7.feature7.title": "Cloud Functions",
  "pricing.section7.feature7.description": "Build and deploy custom Javascript to allow your bot to dynamically access data from anywhere and serve it to your users.",
  "pricing.section7.feature8.title": "Web Messenger",
  "pricing.section7.feature8.description": "Our powerful and flexible responsively designed web messenger can be deployed on the digital property of your choosing.",

  // Enterprise
  "enterprise.section1.heading": "Stackchat for Enterprise",
  "enterprise.section1.description": "With Stackchat for Enterprise, you get a dedicated private cloud deployment, named support staff, an SLA and guaranteed incident response times.",
  "enterprise.section1.link": "Talk to Us",
  "enterprise.section2.heading": "Grow your business with new channels",
  "enterprise.section2.description": "With Stackchat on your website and social channels, you can generate leads and automatically respond to customers' queries 24/7/365.",
  "enterprise.section2.link": "Learn more",
  "enterprise.section3.heading": "Global footprint",
  "enterprise.section3.description": "Improve performance and stay compliant with your country's data sovereignty laws by choosing which region to host your chatbot and customer data.",
  "enterprise.section4.heading": "Your enterprise chatbot delivery partner",
  "enterprise.section4.description": "Partner with our team of awarding-winning experts in conversational technology and enterprise system integrations. Our flexible consulting model means we can work out a plan that's right for you.",
  "enterprise.section5.heading": "Which of your customer conversations can be automated?",
  "enterprise.section5.description": "Spoiler alert: about 70% of them. But not all business functions can or should be automated. Chat with us today to get a unique perspective on the use cases that are best suited for chatbots.",
  "enterprise.section5.link": "Chat now",
  "enterprise.section6.heading": "Security at Stackchat",
  "enterprise.section6.description": "Compliance with industry frameworks and the features you need for running chatbots at massive scale. Only Stackchat gives you an entire suite of tools to build sophisticated, AI-driven chat and voice experiences for the enterprise.",
  "enterprise.section6.card1.title": "GDPR Compliant",
  "enterprise.section6.card1.description": "The Stackchat platform is GDPR compliant. Our platform makes it simple for you to comply with the GDPR and includes everything you need to respect your controller obligations.",
  "enterprise.section6.card2.title": "Massive Scale",
  "enterprise.section6.card2.description": "Our infrastructure has been tested for massive scale and is built to scale out automatically to handle any sort of spikes in traffic you can throw our way.",
  "enterprise.section6.card3.title": "Data Encryption",
  "enterprise.section6.card3.description": "Stackchat encrypts all messages and customer data, both in transit and at rest. Communications between you and Stackchat servers are encrypted via HTTPS and Transport Layer Security (TLS).",
  "enterprise.section6.card4.title": "Business-critical Reliability",
  "enterprise.section6.card4.description": "24x7 monitoring, strong SLAs, a globally distributed, resilient and highly available messaging infrastructure means that your conversational strategy can scale to epic heights.",
  "enterprise.section7.heading": "Detailed reporting for actionable insights",
  "enterprise.section7.description": "Conversational marketing isn't about vanity metrics like opens and clicks. It's about delivering real utility to prospects and existing customers, so we've made it easy to show the real impact Stackchat is having on the metrics that really matter to your business.",
  "enterprise.section8.heading": "Omnichannel conversational marketing",
  "enterprise.section8.description": "Stackchat connects your business to any messaging channel, unifying each customer chat into an omnichannel conversation with a single view of your customer.",
  "enterprise.section8.link": "See all channels",
  "enterprise.section9.heading": "APIs & Webhooks",
  "enterprise.section9.description": "Automate chatbot creation, chatbot publishing and message broadcasts with our APIs.",
  "enterprise.section9.link": "Read more",
  "enterprise.section10.heading": "Best practices",
  "enterprise.section10.description": "Only Stackchat gives you an entire suite of tools to build sophisticated, AI-driven chat and voice experiences for the enterprise: 99.5% uptime SLA, guaranteed response times and compliance with industry frameworks ensures you have everything you need for running chat at massive scale.",
  "enterprise.section10.card1.title": "Message Storage",
  "enterprise.section10.card1.description": "Access the histories of all your chatbots. We store messages and user data in the country of your choice and you can securely access all messages via API.",
  "enterprise.section10.card2.title": "99.95% Uptime SLA",
  "enterprise.section10.card2.description": "Rest easy knowing you are in good hands with our enterprise grade SLA, guaranteeing 99.95% uptime with credits applied to your account when this target isn't met.",
  "enterprise.section10.card3.title": "Change Management",
  "enterprise.section10.card3.description": "Using Stackchat's staged deployment process, you can ensure intelligent assistant updates are thoroughly tested by your QA team prior to shipping.",
  "enterprise.section10.card4.title": "Incident response plan",
  "enterprise.section10.card4.description": "Our team are educated on and trained to follow formal procedures for security events and are required to sign non-disclosure and confidentiality agreements.",
  "enterprise.section10.link": "Get Stackchat free",
  "enterprise.section11.heading": "Build multilingual chatbots",
  "enterprise.section11.description": `Create your chatbot in any language. Our proprietary Conversation Design Markup Language makes it easy to build a "master" bot in any language and then programatically translate it to other languages.`,
  "enterprise.section12.heading": "Integrated with the companies you love",
  "enterprise.section12.description": "We don't want to change the way  you work - we just want to improve it. Stackchat natively integrates with all the tech you're already using.",
  "enterprise.section12.link": "See all integrations",
  "enterprise.section13.heading": "Connect your chatbot to Adobe Experience Cloud",
  "enterprise.section13.description": "Take advantage of your existing investment in Adobe Analytics, AEM Assets and other Experience Cloud products by bringing their power to your customer conversations.",
  "enterprise.section13.link": "Read more",
  "enterprise.section14.heading": "Ready to get started?",
  "enterprise.section14.description": "Sign up for a free account and start building your bot today!",
  "enterprise.section14.link1": "Contact sales",
  "enterprise.section14.link2": "Sign up free",

  // Contact Page
  "contact.heading": "Let's chat",
  "contact.description-line-1": "We'd love to help make your business software more conversational.",
  "contact.description-line-2": "Tell us a little about yourself and we'll set up a call.",

  // Changelog
  "changelog.item.type-feature": "Feature",
  "changelog.item.type-deprecation": "Deprecation",
  "changelog.item.type-update": "Update",
  "changelog.item.type-bugfix": "Bugfix",
  "changelog.item.type-beta": "Beta",

  // Bot Builder
  "bot-builder.hero.title.part1": "Stackchat Studio:",
  "bot-builder.hero.title.part2": "The Ultimate Conversation Builder",
  "bot-builder.hero.description": "Enable any member of your organisation to quickly build, test and deploy custom chatbots.",
  "bot-builder.section-2.title": "Build Voice Search, chatbots, intelligent assistants and more.",
  "bot-builder.section-2.description": "Stackchat Studio gives you the tools to build amazing contextual chat experiences.",
  "bot-builder.step1.label": "Step 1",
  "bot-builder.step1.title": "Design your Chatbot",
  "bot-builder.step1.slide1.text": "More than just text. Add images, menus, buttons and cards to give your bot style and functionality.",
  "bot-builder.step1.slide1.marker-text": "Write your chat script",
  "bot-builder.step1.slide2.text": "Use what your bot learns about its users to create personalized conversations that delight.",
  "bot-builder.step1.slide2.marker-text": "Organise your conversations",
  "bot-builder.step1.slide3.text": "Enhance your bot with connections to the social channels your users love and the business services you rely upon.",
  "bot-builder.step1.slide3.marker-text": "Configure your workflows",
  "bot-builder.step1.slide4.text": "Integrate your Stackchat bot with your existing APIs and services to display dynamic content.",
  "bot-builder.step1.slide4.marker-text": "Connect to your APIs",
  "bot-builder.step2.label": "Step 2",
  "bot-builder.step2.title": "Train AI",
  "bot-builder.step2.ai-smarts.title": "Add AI Smarts",
  "bot-builder.step2.ai-smarts.description": "Give your users an authentic conversational experience with keywords and natural language processing.",
  "bot-builder.step2.user-voice.title": "Give your Users a Voice",
  "bot-builder.step2.user-voice.description": "Embed a voice assistant directly in your website by enabling Stackchat's voice mode to meet user needs and enhance accessibility with AI-driven speech-to-text.",
  "bot-builder.step3.label": "Step 3",
  "bot-builder.step3.title": "Publish",
  "bot-builder.step3.description1": "Connect your Business to the World",
  "bot-builder.step3.description2": "Stackchat omnichannel messaging lets you reach your users on their preferred channels such as Facebook Messenger, your website, WeChat Mini Programs and WeChat Official Accounts.",
}