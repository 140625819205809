import { N0, P500, P700, R600 } from "@stackchat/colors"
import styled, { css } from "styled-components"

export const FormContainer = styled.div`
  ${(props: { padded: boolean }) => css`
    margin-top: ${props.padded ? "48px" : "16px" };
    width: 100%;
  `}

  iframe {
    max-width: 100%;
  }
`

const getLightFormStyles = (accentColor: string = P700) => `
  #form-bottom {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }

  .hs-form {
    max-width: 500px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-top: 0;
    margin-bottom: 0;
    z-index: 1;
    margin-left: auto;
    margin-right: auto;
  }

  .hs-form * {
    outline: none;
  }

  .hs-form fieldset {
    width: 100%;
    margin-bottom: 16px;
  }

  .hs-form fieldset .field {
    margin: 0;
  }

  .hs-form label {
    display: none;
  }

  .hs-form .input {
    background-color: ${ N0 };
    border: 2px solid ${ N0 };
    border-right: none;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .hs-form .input input {
    border: none;
    padding: 2px 8px;
    
    width: 100%;
  }

  .hs-input {
    font-family: 'ProjectFont';
  }

  .hs-form .hs_email {
    width: 100%;
    max-width: 512px;
    margin-bottom: 0;
  }

  .hs-form .hs_submit {
    border-left: 2px solid ${ accentColor };
  }

  .hs-form .hs_submit .actions {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
  }

  .hs-form .hs-button.primary.large {
    font-family: 'ProjectFont';
    border: 2px solid ${ N0 };
    border-left: none;
    border-radius: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    box-shadow: none;
    color: ${ accentColor };
    background-color: ${ N0 };
    padding: 2px 8px;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    line-height: 26px;
  }

  .hs-form .hs_error_rollup {
    display: none;
  }

  .hs-form ul.hs-error-msgs {
    list-style-type: none;
    margin-top: 8px;
  }

  .hs-form ul.hs-error-msgs label, 
  .hs-form ul.hs-error-msgs label a {
    font-family: 'ProjectFont';
    display: block;
    font-size: 12px;
    color: ${ N0 };
  }

  @media only screen and (max-width: 768px) {
    .hs-form {
      flex-direction: column;
      align-items: center;
    }

    .hs-form .input {
      border-right: 2px solid ${N0 };
      border-radius: 4px;
    }

    .hs-form .hs_submit {
      border-left: none;
      width: 100%;
    }

    .hs-form .hs-button.primary.large {
      border-radius: 4px;
      width: 100%;
      margin-top: 8px;
    }

    .hs-form .input input {
      width: 100%;
    }
  }
`

const getDarkFormStyles = (accentColor: string = P700) => `
  #form-bottom {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }

  .hs-form {
    max-width: 500px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-top: 0;
    margin-bottom: 0;
    z-index: 1;
    margin-left: auto;
    margin-right: auto;
  }

  .hs-form * {
    outline: none;
  }

  .hs-form fieldset {
    width: 100%;
    margin-bottom: 16px;
  }

  .hs-form fieldset .field {
    margin: 0;
  }

  .hs-form label {
    display: none;
  }

  .hs-form .input {
    background-color: ${ N0 };
    border: 1px solid ${ accentColor };
    border-right: none;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .hs-form .input input:focus {
    border: 2px solid ${ accentColor };
  }

  .hs-form .input input {
    border: none;
    padding: 2px 8px;
    width: 100%;
    
  }

  .hs-input {
    font-family: 'ProjectFont';
  }

  .hs-form .hs_email {
    width: 100%;
    max-width: 512px;
    margin-bottom: 0;
  }

  .hs-form .hs_submit {
    border-left: 2px solid ${ accentColor };
  }

  .hs-form .hs_submit .actions {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
  }

  .hs-form .hs-button.primary.large {
    border: 2px solid ${ accentColor };
    border-left: none;
    border-radius: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    box-shadow: none;
    color: ${ N0 };
    background-color: ${ accentColor };
    padding: 2px 8px;
    font-family: 'ProjectFont';
    font-weight: 700;
    text-transform: uppercase;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    line-height: 26px;
  }

  .hs-form .hs-button.primary.large:active {
    color: ${ N0 };
    background-color: ${ accentColor };
  }

  .hs-form .hs_error_rollup {
    display: none;
  }

  .hs-form ul.hs-error-msgs {
    list-style-type: none;
    margin-top: 8px;
  }

  .hs-form ul.hs-error-msgs li label {
    font-family: 'ProjectFont';
  }

  .hs-form ul.hs-error-msgs .hs-error-msg {
    display: block;
    font-size: 12px;
    color: ${ R600 };
    text-align: left;
  }
  
  .hs-form ul.hs-error-msgs li label,
  .hs-form ul.hs-error-msgs li label a {
    display: block;
    font-size: 12px;
    color: ${ P500 };
    text-align: left;
  }

  @media only screen and (max-width: 768px) {
    .hs-form {
      flex-direction: column;
      align-items: center;
    }

    .hs-form .input {
      border-right: 1px solid ${ accentColor };
      border-radius: 4px;
    }

    .hs-form .hs_submit {
      border-left: none;
      width: 100%;
    }

    .hs-form .hs-button.primary.large {
      border-radius: 4px;
      width: 100%;
      margin-top: 8px;
    }

    .hs-form .input input {
      width: 100%;
    }
  }
`

export const getStyles = (light: boolean = false, accentColor: string = P700) => {
  if (light === false) {
    return getDarkFormStyles(accentColor)
  } else {
    return getLightFormStyles(accentColor)
  }
}