import { faAngleDown } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as React from "react"
import { FormattedMessage } from "react-intl"
import Button from "../button"
import { Item } from "./items"
import { ListItem, StyledDiv, StyledExternalLink, StyledLink, SubList, SubMenu, SubMenuContainer } from "./styled"
import SubLevelItem from "./SubLevelItem"

interface TopLevelItemProps {
  item: Item
  invertButtons: boolean
  onFinish(): void
}

interface TopLevelItemState {
  isVisible: boolean
}

export default class TopLevelItem extends React.Component<TopLevelItemProps, TopLevelItemState> {
  state: TopLevelItemState = {
    isVisible: false
  }

  toggleVisibility = () => this.setState(prevState => ({
    isVisible: !prevState.isVisible
  }))

  getSubItems = () => {
    const { items } = this.props.item

    if (!items || items.length === 0) {
      return null
    }

    return (
      <SubMenuContainer>
        <SubMenu>
          <SubList hollow={true}>
            {items.map((item, index) => {
              return (
                <SubLevelItem
                  key={index}
                  item={item}
                  onFinish={this.props.onFinish}
                />
              )
            })}
          </SubList>
        </SubMenu>
      </SubMenuContainer>
    )
  }

  getLinkContent = () => {
    const { item, invertButtons } = this.props
    const { appearance, items, title, hollow, inverse } = item

    if (appearance === "text") {
      return (
        <>
          <FormattedMessage id={title} />
          {items && items.length > 0 ? <FontAwesomeIcon icon={faAngleDown} /> : null}
        </>
      )
    }

    return (
      <Button
        hollow={hollow}
        title={<FormattedMessage id={title} />}
        inverse={invertButtons ? !inverse : inverse}
      />
    )
  }

  getLink = () => {
    const { item, onFinish } = this.props

    if (item.items && item.items.length > 0) {
      return (
        <StyledDiv>
          {this.getLinkContent()}
        </StyledDiv>
      )
    }

    if (
      item.link.startsWith("http://")
      || item.link.startsWith("https://")
    ) {
      return (
        <StyledExternalLink href={item.link} target="_blank" onClick={onFinish}>
          {this.getLinkContent()}
        </StyledExternalLink>
      )
    }

    return (
      <StyledLink to={item.link} onClick={onFinish}>
        {this.getLinkContent()}
      </StyledLink>
    )
  }

  render() {
    const { isVisible } = this.state

    if (this.props.invertButtons) {
      // mobile
      return (
        <ListItem onClick={this.toggleVisibility}>
          {this.getLink()}

          {isVisible ? this.getSubItems() : null}
        </ListItem>
      )
    }

    return (
      <ListItem onMouseEnter={this.toggleVisibility} onMouseLeave={this.toggleVisibility}>
        {this.getLink()}

        {isVisible ? this.getSubItems() : null}
      </ListItem>
    )
  }
}