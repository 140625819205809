import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { hexToRGB, N0, N80, N800, P700 } from '@stackchat/colors';
import * as React from 'react';
import styled, { css, keyframes } from 'styled-components';

// Main
export const Main = styled.main`
  padding: 0;
  min-height: 100vh;
  width: 100vw;
  overflow-x: hidden;
`;

// Nav
const navAnimationKeyframes = keyframes`
  0% {
    transform: translateY(0);
    opacity: 0;
  }
  100% {
    transform: translateY(4rem);
    opacity: 1;
  }
`;

export const Nav = styled.nav`
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;
  opacity: 1;

  &.scrolled {
    opacity: 0;
  }

  &.scroll-nav {
    position: sticky;
    top: -4rem;
    opacity: 0;
    height: 0;
    background-color: ${P700 };

    &.scrolled {
      height: auto;
      animation: ${ navAnimationKeyframes } 300ms linear forwards;
    }

    &.fixed {
      height: auto;
      top: 0;
      opacity: 1;
    }
  }
`;

const getInternalSectionStyles = (props: { bg?: string; bgImage: string | string[]; hideResponsiveBg?: boolean }) => {
  if (Array.isArray(props.bgImage)) {
    return css`
      background-color: ${ props.bg };
      position: relative;
      background-size: cover;
      background-position: top 50% left 0%;
      background-image: ${ props.bgImage[0] };

      @media only screen and (max-width: 1440px) {
        background-image: ${ props.bgImage[1] };
      }

      @media only screen and (max-width: 1024px) {
        background-image: ${ props.bgImage[2] };
      }

      @media only screen and (max-width: 768px) {
        background-image: ${ props.hideResponsiveBg ? 'none' : props.bgImage[3] };
      }

      @media only screen and (max-width: 576px) {
        background-image: ${ props.hideResponsiveBg ? 'none' : props.bgImage[4] };
      }
    `;
  }

  return css`
    position: relative;
    background-color: ${ props.bg };
    background-image: ${ props.bgImage };
    background-repeat: no-repeat;

    @media only screen and (max-width: 767.98px) {
      background-image: ${ props.hideResponsiveBg ? 'none' : props.bgImage };
    }
  `;
};

const InternalSection = styled.section`
  width: 100%;
  ${ getInternalSectionStyles }
`;

const SectionContents = styled.div`
  width: 100%;
  max-width: 65.75rem;
  padding: 0.1px 1rem 0;
  margin: 0 auto;
`;

function Section(props: any) {
  const { children, style, fullWidth, ...remainingProps } = props;

  return (
    <InternalSection {...remainingProps}>
      {
        fullWidth
          ? children
          : (
            <SectionContents style={style}>
              {children}
            </SectionContents>
          )
      }
    </InternalSection>
  );
}

/* Image */
const getImageStyles = (props: any) => css`
  display: ${ props.inline ? 'inline-block' : 'block' };
  margin-left: ${ props.center ? 'auto' : 0 };
  margin-right: auto;
  max-width: 100%;
  border-radius: 2px;
  box-shadow: ${props.noShadow ? '' : '0 2px 4px 1px #e2e2e2' };
  margin-top: 1.5rem;
`;

const getCaptionStyles = ({ center }: { center?: boolean }) => css`
  font-size: 0.9rem;
  line-height: 1.35rem;
  color: ${ N80 };
  margin-top: 0.5rem;
  font-style: italic;
  display: block;
  width: 100%;
  text-align: ${ center ? "center" : "left" };
`

const Caption = styled.span`
  ${ getCaptionStyles }
`;

const InternalImg = styled.img`
  ${ getImageStyles }
`;

function Img(props: any) {
  const { inline = false } = props;

  if (inline) {
    return <InternalImg {...props} />;
  }

  return (
    <>
      <InternalImg {...props} />

      {
        props.alt && props.alt.length > 0
          ? (
            <Caption center={props.center}>{props.alt}</Caption>
          )
          : null
      }
    </>
  );
}

const Modal = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${ hexToRGB(N800, 1) };
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 2000;
  transition: all 250ms linear;
  
  img {
    margin-left: auto;
    max-width: 90%;
    max-height: 90%;
    box-shadow: none;
  }

  ${ Caption } {
    text-align: center;
    color: ${ N0 };
    margin-top: 1rem;
    font-size: 120%;
  }

  svg {
    font-size: 24px;
    position: absolute;
    right: 16px;
    top: 16px;
    cursor: pointer;
  }

  @media only screen and (max-width: 575.99px) {
    img {
      max-width: 80%;
      max-height: 80%;
    }

    ${ Caption } {
      margin-top: 0.5rem;
      font-size: 100%;
    }

    svg {
      right: 8px;
      top: 8px;
    }
  }
`

function ImageLightBox(props: any) {
  const [isImageShownInLightBox, setIsImageShownInLightBox] = React.useState(false)

  const toggleImageIsShownInLightBox = () => setIsImageShownInLightBox(_ => !_)

  if (!props.inline) {
    return (
      <>
        <Img {...props} onClick={toggleImageIsShownInLightBox} />

        {
          isImageShownInLightBox
            ? (
              <Modal>
                <Img {...props} />

                <FontAwesomeIcon
                  icon={faTimes}
                  color={N0}
                  onClick={toggleImageIsShownInLightBox}
                />
              </Modal>
            )
            : null
        }
      </>
    )
  }

  return <Img {...props} />
}

// Article
const Article = styled.article`
  width: 100%;
  max-width: 65.75rem;
  margin: 0 auto;
  padding: 0 1rem;
`;

const LayoutElements = {
  img: ImageLightBox,
  main: Main,
  article: Article,
  nav: Nav,
  section: Section
};

export default LayoutElements;
