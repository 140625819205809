interface Author {
  name: string
  alias: string
  image: string
  socials: {
    twitter?: string
    linkedin?: string
  }
}

export const AUTHORS: Author[] = [
  {
    name: "Marcus Robinson",
    alias: "@marrob",
    image: "/blog-images/authors/marcus.jpg",
    socials: {
      linkedin: "https://www.linkedin.com/in/marcus1robinson/",
      twitter: "https://twitter.com/stackchatapp"
    }
  },
  {
    name: "Mark Szumowski",
    alias: "@mszu",
    image: "",
    socials: {
      linkedin: "https://linkedin.com/in/markszumowski"
    }
  },
  {
    name: "Parth Mehta",
    alias: "@iamdifferent",
    image: "/blog-images/authors/parth.jpg",
    socials: {
      twitter: "https://twitter.com/iamdifferent90",
      linkedin: "https://www.linkedin.com/in/parthmehta90/"
    }
  },
  {
    name: "Duncan Tweed",
    alias: "@tweedle",
    image: "/blog-images/authors/duncan.jpg",
    socials: {
      linkedin: "https://linkedin.com/in/duncan-tweed-b694b277"
    }
  },
  {
    name: "Sam Banks",
    alias: "@sbanks",
    image: "/blog-images/authors/sam.jpg",
    socials: {
      linkedin: "https://www.linkedin.com/in/sam-banks-3a733348/"
    }
  },
  {
    name: "Britney Rumsey",
    alias: "@brit",
    image: "",
    socials: {}
  },
  {
    name: "Brad Prichard",
    alias: "@brad",
    image: "/blog-images/authors/brad.jpg",
    socials: {
      linkedin: "https://www.linkedin.com/in/bradley-a-prichard-0068531/"
    }
  }
]