import TextElements from "components/new-design-system/text-elements"
import { graphql, StaticQuery } from "gatsby"
import * as React from "react"
import { ArticleDate, ArticleLink, Background, RelatedArticleContainer, RelatedArticleHero } from "./styled"

interface RelatedArticleProps {
  slug: string
}

interface Article {
  fields: {
    slug: string
  }
  frontmatter: {
    date: string
    hero: string
    path: string
    title: string
  }
}

interface StaticQueryData {
  allMdx: {
    edges: Array<{
      node: Article
    }>
  }
}

export default function RelatedArticle(props: RelatedArticleProps) {
  // methods
  const getArticle = (data: StaticQueryData): React.ReactNode => {
    const edges = data.allMdx.edges
    const requestedEdge = edges.find(
      edge => edge.node.frontmatter.path === props.slug
    )

    if (!requestedEdge) {
      return null
    }

    const { hero, date, title, path } = requestedEdge.node.frontmatter

    return (
      <ArticleLink to={path}>
        <RelatedArticleContainer>
          <Background />

          <RelatedArticleHero style={{ backgroundImage: `url('${ hero }')` }} />

          <ArticleDate>{date}</ArticleDate>

          <TextElements.h5
            style={{
              marginTop: 0
            }}
          >
            {title}
          </TextElements.h5>
        </RelatedArticleContainer>
      </ArticleLink>
    )
  }

  // render
  return (
    <StaticQuery
      query={
        graphql`
          query {
            allMdx {
              edges {
                node {
                  fields {
                    slug
                  }
                  frontmatter {
                    hero
                    title
                    path
                    date(formatString: "MMMM DD, YYYY")
                  }
                }
              }
            }
          }
        `
      }
      render={getArticle}
    />
  )
}