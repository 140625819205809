import TextElements from "components/new-design-system/text-elements"
import * as React from "react"
import { ContentWrapper, Header, HeroWrapper, Image } from "./styled"

interface HeroProps {
  image?: string
  title: string
}

export default function Hero(props: HeroProps) {
  return (
    <>
      <Header>
        <TextElements.h2>
          {props.title}
        </TextElements.h2>
      </Header>

      {
        !props.image
          ? null
          : (
            <HeroWrapper>
              <ContentWrapper>
                <Image
                  style={{
                    backgroundImage: `url("${ props.image }")`
                  }}
                />
              </ContentWrapper>
            </HeroWrapper>
          )
      }
    </>
  )
}