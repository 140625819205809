// ILLUSTRATIONS
/*
  "home.engage.illustration.voicesearch": "声音搜索",
  "home.engage.illustration.conversationalcommerce": "会话商务",
  "home.engage.illustration.servicedelivery": "服务交付",
  "home.engage.illustration.socialcampaigns": "社交活动",
*/

// HUBSPOT FORMS
// These values are now managed via the Hubspot dashboard
// https://app.hubspot.com/forms/3371700/editor/05686a2c-7f73-4adf-ab01-750dcbcf06be/edit/form
/*
  "contact.form.firstname.label": "名字",
  "contact.form.firstname.placeholder": "你的名字",
  "contact.form.lastname.label": "姓",
  "contact.form.lastname.placeholder": "你的姓氏",
  "contact.form.email.label": "工作电邮",
  "contact.form.email.placeholder": "you@yourcompany.com",
  "contact.form.jobtitle.label": "你的职位名称”,
  "contact.form.jobtitle.founder": "创办人",
  "contact.form.jobtitle.executive": "行政人员",
  "contact.form.jobtitle.productmanager": "产品经理",
  "contact.form.jobtitle.sales": "销售",
  "contact.form.jobtitle.marketing": "营销",
  "contact.form.jobtitle.developer": "开发人员",
  "contact.form.jobtitle.customersuccessmanager": "客户成功经理",
  "contact.form.phone.label": "电话号码",
  "contact.form.phone.placeholder": "你的电话号码",
  "contact.form.thankyou": "谢谢，我们很快就会联系您！”,
*/

export const zh: Record<string, string> = {
  // Header
  "header.platform.title": "平台",
  "header.resources.title": "全部资源",
  "header.resources.items.item1": "实用信息",
  "header.resources.items.item2": "更多精选",
  "header.pricing.title": "价格",
  "header.enterprise.title": "企业合作",
  "header.demo.title": "产品演示",
  "header.signup.title": "免费注册",
  "header.signin.title": "登录",

  // Footer
  "footer.product.title": "产品信息",
  "footer.product.bot-builder": "机器人创建器",
  "footer.product.pricing": "价格",
  "footer.product.changelog": "变更日志",
  "footer.learn.title": "了解更多",
  "footer.learn.blog": "更多精选",
  "footer.learn.docs": "实用信息",
  "footer.company.title": "公司信息",
  "footer.company.contact": "联系我们",
  "footer.terms": "服务条款",
  "footer.privacy": "隐私政策",

  // Registration Form
  "registration-form.button-title": "创建免费帐户",

  // Home Page
  "home.hero.heading": "通过令人惊叹的聊天体验带动业务发展",
  "home.hero.description1": "能够达到吸引客户、促进互动以及最大化维持客户关系的目标。",
  "home.hero.description2": "为人工智能、多渠道沟通以及语音自动识别提供全球领先的解决方案。",
  "home.hero.link": "免费下载Stackchat",
  "home.hero.cta.heading": "会话式营销变得简单",
  "home.hero.cta.description": "了解Stackchat语音APP将如何在您的网站呈现。",
  "home.attract.heading": "吸引",
  "home.attract.description": "使用我们预先搭建的聊天机器人，只需点击几下即可与您的未来客户联系。",
  "home.attract.link": "查看所有预建聊天机器人",
  "home.engage.heading": "互动",
  "home.engage.description": "通过规模化的自动回复以及个性化制定客户对话来推动客户参与互动。",
  "home.engage.link": "查看产品演示",
  "home.retain.heading": "维护",
  "home.retain.description": "结合人工智能和人工在线聊天，可以让您完成从有着适当距离的客户关系到提供全天候支持帮助的转变。",
  "home.retain.link": "了解全天候支持帮助",
  "home.connect.heading": "联合",
  "home.connect.description": "Stackchat可以将您的业务连接到任何消息渠道，将每个客户的聊天整合到具有客户单一视角的全渠道对话中。",
  "home.connect.link": "查看所有渠道",
  "home.enterprise.heading": "企业级规模和安全性。",
  "home.enterprise.description": "企业版聊天机器人会利用其经过多次实战测试的GDPR兼容平台保护客户数据安全。",
  "home.enterprise.link": "阅读更多",
  "home.features.heading": "立即开始搭建",
  "home.features.description": "Stackchat是您的多合一对话营销平台，可为您的打造，管理和优化聊天机器人。",
  "home.features.link": "免费下载Stackchat",
  "home.features.card1.title": "NLP AI应用背景",
  "home.features.card1.description": "无论您组织中的成员背景如何，都能允许他们为您的客户设计、配置人工智能驱动的会话体验。",
  "home.features.card1.link": "阅读更多",
  "home.features.card2.title": "内置语音",
  "home.features.card2.description": "在移动端和网站上使用我们经过验证的语音AI技术，并且依照品牌需求定制能够检测品牌特殊词汇的模型。",
  "home.features.card2.link": "阅读更多",
  "home.features.card3.title": "搭建机器人",
  "home.features.card3.description": "Stackchat Studio机器人搭建器具有直观，易使用以及在同类中处于领先地位的特点。可为您在搭建聊天机器人的过程中提供支持和灵活性。",
  "home.features.card3.link": "阅读更多",
  "home.features.card4.title": "逻辑分析",
  "home.features.card4.description": "Stackchat内置分析套件可让您准确了解用户是如何与机器人互动。",
  "home.features.card4.link": "阅读更多",
  "home.subscribe.heading": "订阅",
  "home.subscribe.description": "接收相关行业趋势，新功能，学习指南等方面的更新。",
  "home.industry.heading": "扩大业务规模。",
  "home.industry.description": "近70％的客户询问可以被自动回复。了解如何在您的行业中应用会话式营销。",
  "home.industry.card1.title": "旅游观光与接待",
  "home.industry.card1.description": "机器人可以浏览航班，酒店客房和汽车租赁等信息；也可以提供价格，折扣代码以及常见问题的解答。",
  "home.industry.card1.link": "阅读更多",
  "home.industry.card2.title": "高等教育",
  "home.industry.card2.description": "机器人可以在许多其他应用程序中提供图书馆，健身房和实验室营业时间；同时也能提供课程注册截止日期和经济援助申请的提醒。",
  "home.industry.card2.link": "阅读更多",
  "home.industry.card3.title": "金融",
  "home.industry.card3.description": "在许多其他运用实例中，Bots可以帮助处理付款，显示利率比较，以及检查卡片应用程序的后端状态。",
  "home.industry.card3.link": "阅读更多",
  "home.industry.card4.title": "零售和电子商务",
  "home.industry.card4.description": "机器人可以帮助检索价格，产品规格和可用性，强调促销优惠，并提供运输和安装等相关信息。",
  "home.industry.card4.link": "阅读更多",
  "home.industry.link1": "联系我们的销售",
  "home.industry.link2": "价格",
  "home.channels.link": "查看所有渠道",
  "home.cta2.heading": "准备开始了吗？",
  "home.cta2.description": "注册一个免费帐户，立即开始打造您的机器人！",
  "home.cta2.link1": "联系我们的销售",
  "home.cta2.link2": "免费注册",

  // Pricing Page
  "pricing.billed-monthly": "每月月付",
  "pricing.billed-annually": "按月计费",
  "pricing.section1.heading": "计划灵活，定价简洁",
  "pricing.section1.description1": "今天，让我们使用免费版开始搭建机器人来体验Stackchat。",
  "pricing.section1.description2": "无需保证金，无需信用卡支付。",
  "pricing.tab1.title": "个人版",
  "pricing.tab1.column1.title": "免费",
  "pricing.tab1.column1.description": "开始使用聊天机器人工作的所有基础内容",
  "pricing.tab1.column1.price": "0元",
  "pricing.tab1.column1.link": "免费试用",
  "pricing.tab1.column1.list.item1": "可用于1款聊天应用",
  "pricing.tab1.column1.list.item2": "网页消息接入",
  "pricing.tab1.column1.list.item3": "1个额外的集成接入",
  "pricing.tab1.column1.list.item4": "每月最多50段聊天会话",
  "pricing.tab1.column1.list.item5": "125条语音转文本消息",
  "pricing.tab1.column2.title": "初始版",
  "pricing.tab1.column2.description": "适用于第一次搭建真实环境下聊天机器人的项目",
  "pricing.tab1.column2.link": "联系我们的销售",
  "pricing.tab1.column2.list.item1": "可用于2款聊天应用",
  "pricing.tab1.column2.list.item2": "网页消息接入",
  "pricing.tab1.column2.list.item3": "3个额外的集成接入",
  "pricing.tab1.column2.list.item4": "每月最多500段聊天会话",
  "pricing.tab1.column2.list.item5": "1250条语音转文本消息",
  "pricing.tab1.column2.price": "1,199元",
  "pricing.tab2.title": "团队版",
  "pricing.tab2.column1.title": "进阶版",
  "pricing.tab2.column1.description": "适用于准备扩充聊天机器人受众的客户",
  "pricing.tab2.column1.price": "3,499元",
  "pricing.tab2.column1.link": "联系我们的销售",
  "pricing.tab2.column1.list.item1": "可用于3款聊天应用",
  "pricing.tab2.column1.list.item2": "网页消息接入",
  "pricing.tab2.column1.list.item3": "3个额外的集成接入",
  "pricing.tab2.column1.list.item4": "每个月最多5000段聊天会话",
  "pricing.tab2.column1.list.item5": "12500条语音转文本消息",
  "pricing.tab2.column2.title": "高级版",
  "pricing.tab2.column2.description": "适用于准备规模化聊天机器人策略的企业",
  "pricing.tab2.column2.price": "12,999元",
  "pricing.tab2.column2.link": "联系我们的销售",
  "pricing.tab2.column2.list.item1": "可用于5款聊天应用",
  "pricing.tab2.column2.list.item2": "网页消息接入",
  "pricing.tab2.column2.list.item3": "无限制集成接入",
  "pricing.tab2.column2.list.item4": "每个月最多50000段聊天会话",
  "pricing.tab2.column2.list.item5": "125000条语音转文本消息",
  "pricing.tab3.title": "企业版",
  "pricing.tab3.column.title": "企业版",
  "pricing.tab3.column.description": "适用于拥有一千名以上员工、拥有高流量的网站或准备实行多重团队会话体验管理的企业",
  "pricing.tab3.column.link": "联系我们的销售",
  "pricing.tab3.column.list.item1": "12周工作人员入驻企业提供服务，包括技术接入设置、Adobe Experience云接入以及企业内团队培训",
  "pricing.tab3.column.list.item2": "企业预置型部署、混合云配置或提供独立AWS账户基础配置搭建",
  "pricing.tab3.column.list.item3": "提供专业服务团队为您创建AI模型以及设计推送您的只能助手",
  "pricing.tab3.column.list.item4": "数据留存定制、合规及政策审核",
  "pricing.section2.heading": "准备好探索如何使用Stackchat的语音体验来解锁新的客户吗？",
  "pricing.section2.link": "联系我们的销售",
  "pricing.section2.main-link": "查看完整比较",
  "pricing.section3.heading": "全版本比较",
  "pricing.section3.table.column1.pricing": "定价",
  "pricing.section3.table.column1.term": "条款",
  "pricing.section3.table.column1.availableBots": "可用的机器人",
  "pricing.section3.table.column1.additionalPrice": "每个额外添加的机器人价格",
  "pricing.section3.table.column1.monthlyConversations": "月度聊天会话",
  "pricing.section3.table.column1.pricePerConversation": "每一段额外的聊天会话价格",
  "pricing.section3.table.column1.speechToTextMessages": "月度语音转文字",
  "pricing.section3.table.column1.pricePerSTM": "每一条额外的语音转文字价格",
  "pricing.section3.table.column1.volumeDiscounts": "价格优惠",
  "pricing.section3.table.column1.integrationsIncluded": "已包含的集成接入",
  "pricing.section3.table.column1.speechToText": "语音转文本语音能力",
  "pricing.section3.table.column1.supportPlan": "支持计划",
  "pricing.section3.table.column1.deployment": "部署",
  "pricing.section3.table.column1.studioUsers": "Stackchat工作台使用者",
  "pricing.section3.table.column1.strategyAndRoadmap": "会话策略和路线图",
  "pricing.section3.table.column1.planningAndExecution": "规划、执行和运维",
  "pricing.section3.table.column2.title": "免费",
  "pricing.section3.table.column2.description": "万事俱备，让我们准备给Stackchat提供一场测试运行吧",
  "pricing.section3.table.column2.link": "免费尝试",
  "pricing.section3.table.column2.pricing": "/月付",
  "pricing.section3.table.column2.price": "0元",
  "pricing.section3.table.column2.term": "---",
  "pricing.section3.table.column2.availableBots": "1",
  "pricing.section3.table.column2.additionalPrice": "---",
  "pricing.section3.table.column2.monthlyConversations": "50",
  "pricing.section3.table.column2.pricePerConversation": "---",
  "pricing.section3.table.column2.speechToTextMessages": "125",
  "pricing.section3.table.column2.pricePerSTM": "---",
  "pricing.section3.table.column2.volumeDiscounts": "---",
  "pricing.section3.table.column2.integrationsIncluded": "网页消息和一个额外的集成接入",
  "pricing.section3.table.column2.supportPlan": "基础",
  "pricing.section3.table.column2.deployment": "多租赁服务器托管",
  "pricing.section3.table.column2.studioUsers": "1",
  "pricing.section3.table.column2.strategyAndRoadmap": "---",
  "pricing.section3.table.column2.planningAndExecution": "---",
  "pricing.section3.table.column3.title": "初始版",
  "pricing.section3.table.column3.description": "开启新会话项目所需的所有准备事项",
  "pricing.section3.table.column3.link": "联系我们",
  "pricing.section3.table.column3.pricing": "/月",
  "pricing.section3.table.column3.price": "1,199元",
  "pricing.section3.table.column3.term": "月付",
  "pricing.section3.table.column3.availableBots": "2",
  "pricing.section3.table.column3.additionalPrice": "---",
  "pricing.section3.table.column3.monthlyConversations": "500",
  "pricing.section3.table.column3.pricePerConversation": "---",
  "pricing.section3.table.column3.speechToTextMessages": "1,250",
  "pricing.section3.table.column3.pricePerSTM": "---",
  "pricing.section3.table.column3.volumeDiscounts": "---",
  "pricing.section3.table.column3.integrationsIncluded": "网页消息和三个额外的集成接入",
  "pricing.section3.table.column3.supportPlan": "基础",
  "pricing.section3.table.column3.deployment": "多租赁服务器托管",
  "pricing.section3.table.column3.studioUsers": "2",
  "pricing.section3.table.column3.strategyAndRoadmap": "---",
  "pricing.section3.table.column3.planningAndExecution": "---",
  "pricing.section3.table.column4.title": "进阶版",
  "pricing.section3.table.column4.description": "产品灵活可测量，触达更多用户",
  "pricing.section3.table.column4.link": "联系我们",
  "pricing.section3.table.column4.pricing": "/月",
  "pricing.section3.table.column4.price": "3,499元",
  "pricing.section3.table.column4.term": "月付",
  "pricing.section3.table.column4.availableBots": "3",
  "pricing.section3.table.column4.additionalPrice": "350元",
  "pricing.section3.table.column4.monthlyConversations": "5,000",
  "pricing.section3.table.column4.pricePerConversation": "0.35元",
  "pricing.section3.table.column4.speechToTextMessages": "12,500",
  "pricing.section3.table.column4.pricePerSTM": "0.35元",
  "pricing.section3.table.column4.volumeDiscounts": "---",
  "pricing.section3.table.column4.integrationsIncluded": "网页消息和三个额外的集成接入",
  "pricing.section3.table.column4.supportPlan": "基础",
  "pricing.section3.table.column4.deployment": "多租赁服务器托管",
  "pricing.section3.table.column4.studioUsers": "5",
  "pricing.section3.table.column4.strategyAndRoadmap": "---",
  "pricing.section3.table.column4.planningAndExecution": "---",
  "pricing.section3.table.column5.title": "高级版",
  "pricing.section3.table.column5.description": "测量及提供大型产品以应对更多用户",
  "pricing.section3.table.column5.link": "联系我们",
  "pricing.section3.table.column5.pricing": "/月",
  "pricing.section3.table.column5.price": "12,999元",
  "pricing.section3.table.column5.term": "年付",
  "pricing.section3.table.column5.availableBots": "5",
  "pricing.section3.table.column5.additionalPrice": "350元",
  "pricing.section3.table.column5.monthlyConversations": "50,000",
  "pricing.section3.table.column5.pricePerConversation": "0.28元",
  "pricing.section3.table.column5.speechToTextMessages": "125,000",
  "pricing.section3.table.column5.pricePerSTM": "0.28元",
  "pricing.section3.table.column5.volumeDiscounts": "---",
  "pricing.section3.table.column5.integrationsIncluded": "网页消息和无限制额外集成接入",
  "pricing.section3.table.column5.supportPlan": "标准",
  "pricing.section3.table.column5.deployment": "多租赁服务器托管",
  "pricing.section3.table.column5.studioUsers": "10",
  "pricing.section3.table.column5.planningAndExecution": "---",
  "pricing.section3.table.column6.title": "企业版",
  "pricing.section3.table.column6.description": "运行企业产品测量的所有必需事项",
  "pricing.section3.table.column6.link": "联系我们",
  "pricing.section3.table.column6.pricing": "联系我们",
  "pricing.section3.table.column6.term": "年付",
  "pricing.section3.table.column6.availableBots": "无限制",
  "pricing.section3.table.column6.additionalPrice": "---",
  "pricing.section3.table.column6.monthlyConversations": "100,000",
  "pricing.section3.table.column6.pricePerConversation": "0.28元",
  "pricing.section3.table.column6.speechToTextMessages": "250,000",
  "pricing.section3.table.column6.pricePerSTM": "0.28元",
  "pricing.section3.table.column6.volumeDiscounts": "可用的",
  "pricing.section3.table.column6.integrationsIncluded": "网页消息和无限制额外集成接入",
  "pricing.section3.table.column6.supportPlan": "高级",
  "pricing.section3.table.column6.deployment": "独立AWS或场内服务专用基础设施",
  "pricing.section3.table.column6.studioUsers": "基础需要",
  "pricing.section3.table.separator.chatbot-volume": "聊天机器人数量与价格",
  "pricing.section3.table.separator.volume-rates": "会话数量与价格",
  "pricing.section3.table.separator.channel-integrations": "渠道集成接入",
  "pricing.section3.table.separator.support-levels": "支持级别",
  "pricing.section3.table.separator.features": "功能",
  "pricing.section3.table.separator.professional-services": "专业服务",
  "pricing.section3.tooltip.integrations": "一段接入是指我们支持的一个第三方服务支持自然语言处理和分析工具与机器人产生连接。",
  "pricing.section3.tooltip.conversations": "在24小时时间段内，终端用户和您的机器人在一段会话中最多可以拥有不超过50条互动消息。",
  "pricing.section3.tooltip.support": "如需了解我们支援计划的更多具体细节，请查看页面底部表格。",
  "pricing.section4.heading": "准备申请一个Stackchat工作台？",
  "pricing.section4.link": "联系我们的销售",
  "pricing.section5.heading": "支持计划比较",
  "pricing.section5.table.column1.comms": "会话渠道",
  "pricing.section5.table.column1.supportHours": "支持时长",
  "pricing.section5.table.column1.namedPersonel": "指定人员",
  "pricing.section5.table.column1.generalIssues": "常规问题",
  "pricing.section5.table.column1.degradedService": "降级服务",
  "pricing.section5.table.column1.downtime": "生产停工时间",
  "pricing.section5.table.column1.uptime": "服务担保",
  "pricing.section5.column2.title": "基础",
  "pricing.section5.column2.description": "小型商务综合支持，针对免费、初始和进阶版",
  "pricing.section5.table.column2.comms": "邮箱、Github & Slack",
  "pricing.section5.table.column2.supportHours": "不保证",
  "pricing.section5.table.column2.namedPersonel": "---",
  "pricing.section5.table.column2.generalIssues": "最佳效果",
  "pricing.section5.table.column2.degradedService": "最佳效果",
  "pricing.section5.table.column2.downtime": "最佳效果",
  "pricing.section5.table.column2.uptime": "---",
  "pricing.section5.column3.title": "标准",
  "pricing.section5.column3.description": "高级用户可以享有免费时长的扩展支持",
  "pricing.section5.table.column3.comms": "邮箱、Github & Slack",
  "pricing.section5.table.column3.supportHours": "工作日9AM-8PM（AEST）",
  "pricing.section5.table.column3.generalIssues": "小于8个工作小时",
  "pricing.section5.table.column3.degradedService": "小于4个工作小时",
  "pricing.section5.table.column3.downtime": "小于2个工作小时",
  "pricing.section5.table.column3.uptime": "---",
  "pricing.section5.column4.title": "高级",
  "pricing.section5.column4.description": "企业版提供全方位支持",
  "pricing.section5.table.column4.comms": "手机、邮箱、Github & Slack",
  "pricing.section5.table.column4.supportHours": "24/7",
  "pricing.section5.table.column4.generalIssues": "小于4个工作小时",
  "pricing.section5.table.column4.degradedService": "小于2个工作小时",
  "pricing.section5.table.column4.downtime": "小于1个工作小时",
  "pricing.section5.table.column4.uptime": "99.95%",
  "pricing.section5.table.separator.response": "负责时长",
  "pricing.section5.table.separator.sla": "服务水平协议 (SLA)",
  "pricing.section6.heading": "对我们的介绍产生兴趣?即刻注册免费账户体验Stackchat。.",
  "pricing.section6.link": "免费试用",
  "pricing.section7.heading": "每个版本都包含了很棒的Stackchat功能",
  "pricing.section7.description": "我们所有版本的服务都致力于为您创建满意的用户会话体验能力。",
  "pricing.section7.feature1.title": "Stackchat工作台",
  "pricing.section7.feature1.description": "直观、便捷、出色，Stackchat工作台机器人搭建可以提供您构建任何聊天机器人所需的能力和灵活性。",
  "pricing.section7.feature2.title": "数据安全法规合约",
  "pricing.section7.feature2.description": "Stackchat坚决遵从GDPR以及中国网络安全法，可以让您在全球任何地方无忧部署您的聊天机器人。",
  "pricing.section7.feature3.title": "CRM及用户授权",
  "pricing.section7.feature3.description": "您的机器人和用户的每段会话都会作为一段新的记录保存在Stackchat的CRM系统中，包括来自跨平台的沟通。",
  "pricing.section7.feature4.title": "社交平台",
  "pricing.section7.feature4.description": "你可以在Facebook Messenger、微信公众号及小程序这些世界流程的社交平台上部署您的机器人。",
  "pricing.section7.feature5.title": "分析",
  "pricing.section7.feature5.description": "Stackchat具备全内置分析组件，可以让您充分了解用户与机器人的互动情况，您也可以使用Adobe分析来作为第三方可信服的数据监测来源。",
  "pricing.section7.feature6.title": "详细日志",
  "pricing.section7.feature6.description": "您的聊天机器人发生了意料外的情况?通过直接访问日志可准确找出发生的问题。",
  "pricing.section7.feature7.title": "云函数",
  "pricing.section7.feature7.description": "通过建立和部署定制化的Javascript可以让您的机器人动态访问实时数据并为您的用户提供服务。",
  "pricing.section7.feature8.title": "网络消息",
  "pricing.section7.feature8.description": "我们强大及灵活可靠的网络消息设计可以让您放心的部署在您选择的数字资产上",

  // Enterprise
  "enterprise.section1.heading": "企业版Stackchat",
  "enterprise.section1.description": "借助企业版Stackchat，您可以获得用来支持工作人员、SLA以及确保意外响应时间的私人专属的云部署。",
  "enterprise.section1.link": "联系我们",
  "enterprise.section2.heading": "通过新渠道拓展业务",
  "enterprise.section2.description": "在您网站以及社交渠道上应用Stackchat，您可以开发潜在客户以及全年无休地自动回复他们的需求。",
  "enterprise.section2.link": "了解更多",
  "enterprise.section3.heading": "全球分布范围",
  "enterprise.section3.description": "通过选择托管聊天机器人和客户数据的区域，从而提高性能并始终遵守您所在国家/地区的数据主权法律。",
  "enterprise.section4.heading": "你的企业版聊天机器人带来合作",
  "enterprise.section4.description": "与我们在会话技术和企业系统集成领域获奖的专家团队合作。我们灵活的咨询模式意味着我们可以制定出适合您的计划。",
  "enterprise.section5.heading": "您的哪些客户对话可以自动化？",
  "enterprise.section5.description": "剧透警报：大约70％。但并非所有业务功能都可以或应该自动化。联系我们以获得最适合聊天机器人应用的独特角度。",
  "enterprise.section5.link": "立即沟通",
  "enterprise.section6.heading": "Stackchat的安全性",
  "enterprise.section6.description": "符合行业要求以及具备大规模运行聊天机器人所需的功能。只有Stackchat为您提供了为企业搭建成熟，人工智能驱动的聊天和语音体验所需的工具。",
  "enterprise.section6.card1.title": "符合GDPR标准",
  "enterprise.section6.card1.description": "Stackchat平台符合GDPR标准。我们的平台使您可以轻松遵守包含财务承付款项在内的GDPR标准。",
  "enterprise.section6.card2.title": "规模化",
  "enterprise.section6.card2.description": "我们的基础设施已经过大规模测试，并且可以自动扩展以处理任何您运行中出现的问题。",
  "enterprise.section6.card3.title": "数据加密",
  "enterprise.section6.card3.description": "Stackchat在传输和静止时加密所有消息和客户数据。您和Stackchat服务器之间的通信信息通过HTTPS和传输层安全性（TLS）进行加密。",
  "enterprise.section6.card4.title": "关键业务的可靠性",
  "enterprise.section6.card4.description": "消息传递基础架构具备全天候监控，强大的SLA，全球分布，可及时恢复以及和高可用性这些特点，意味着您的会话策略可以达到到新的高度。",
  "enterprise.section7.heading": "可执行洞察的详细报告",
  "enterprise.section7.description": "对话营销不是打开和点击这样的虚拟指标。它旨在为潜在客户和现有客户提供真正的实用性，因此我们可以轻松展示Stackchat对您的业务真正重要的指标所产生的影响。",
  "enterprise.section8.heading": "全渠道对话式营销",
  "enterprise.section8.description": "Stackchat可以将您的业务连接到任何消息渠道，将每个客户的聊天整合到具有客户单一视角的全渠道对话中。",
  "enterprise.section8.link": "查看所有渠道",
  "enterprise.section9.heading": "API和Webhooks",
  "enterprise.section9.description": "使用我们的API进行自动聊天机器人的创意，聊天机器人的发布和传递消息。",
  "enterprise.section9.link": "阅读更多",
  "enterprise.section10.heading": "最佳实践",
  "enterprise.section10.description": "只有Stackchat为您提供了为企业构建复杂的，人工智能驱动的聊天和语音体验的一整套工具：SLA 99.5％的正常运行时间，保证了响应时间以及与行业框架的兼容性确保您拥有大规模运行聊天所需的一切。",
  "enterprise.section10.card1.title": "信息储存",
  "enterprise.section10.card1.description": "访问所有聊天机器人的历史记录。我们在您选择的国家/地区存储消息和用户数据，您可以通过API安全地访问所有消息。",
  "enterprise.section10.card2.title": "SLA 99.95％的正常运行时间",
  "enterprise.section10.card2.description": "通过我们的企业级SLA轻松了解您的情况，确保99.95％的正常运行时间，如果未达到此目标，将对您的帐户进行积分补偿。",
  "enterprise.section10.card3.title": "改变管理方式",
  "enterprise.section10.card3.description": "使用Stackchat的分阶段部署过程，您可以确保在发货之前由QA团队对智能助理的更新进行全面测试。",
  "enterprise.section10.card4.title": "突发事件处理",
  "enterprise.section10.card4.description": "我们的团队接受过教育并接受过正式的安全事件程序培训，并且需要签署保密协议。",
  "enterprise.section10.link": "免费下载Stackchat",
  "enterprise.section11.heading": "搭建多语言聊天机器人。",
  "enterprise.section11.description": "创建多语言的聊天机器人。我们专利的语言设计使得搭建任何语言的聊天机器人以及语言之间的转换变得更加容易。",
  "enterprise.section12.heading": "与您喜爱的公司相连接",
  "enterprise.section12.description": "我们不想改变你的工作方式 - 我们只想改善它。Stackchat自然而然地与您已经使用的技术相连接。",
  "enterprise.section12.link": "查看所有连接",
  "enterprise.section13.heading": "将您的聊天机器人与Adobe Experience Cloud相连接",
  "enterprise.section13.description": "利用您在Adobe Analytics，AEM资产和其他Experience Cloud产品上的现有投资，为客户对话提供强大功能。",
  "enterprise.section13.link": "阅读更多",
  "enterprise.section14.heading": "准备开始了吗？",
  "enterprise.section14.description": "注册一个免费帐户，立即开始打造您的机器人！",
  "enterprise.section14.link1": "联系我们的销售",
  "enterprise.section14.link2": "免费注册",

  // Contact Page
  "contact.heading": "我们聊聊吧",
  "contact.description-line-1": "我们很乐意使您的商务软件更具对话性。",
  "contact.description-line-2": "请告诉我们您的信息，我们稍后会联系您。",

  // Changelog
  "changelog.item.type-feature": "特点",
  "changelog.item.type-deprecation": "弃用",
  "changelog.item.type-update": "更新",
  "changelog.item.type-bugfix": "问题修复",
  "changelog.item.type-beta": "BETA",

  "bot-builder.hero.title.part1": "Stackchat 工作台:",
  "bot-builder.hero.title.part2": "终极对话生成器 ",
  "bot-builder.hero.description": "使组织的任何成员都可以快速构建，测试和部署自定义聊天机器人。",
  "bot-builder.section-2.title": "构建语音搜索，聊天机器人，智能助手等。",
  "bot-builder.section-2.description": "Stackchat 工作台为您提供了用来建立令人惊叹的上下文聊天体验的工具。",
  "bot-builder.step1.label": "第一步",
  "bot-builder.step1.title": "设计您的聊天机器人",
  "bot-builder.step1.slide1.text": "不仅仅是文字，通过添加图像，菜单，按钮和卡片也能赋予您的机器人风格和功能。",
  "bot-builder.step1.slide1.marker-text": "编写您的聊天脚本。",
  "bot-builder.step1.slide2.text": "第二张幻灯片需要实际的文案。",
  "bot-builder.step1.slide2.marker-text": "组织您的对话",
  "bot-builder.step1.slide3.text": "第三张幻灯片需要实际的文案。",
  "bot-builder.step1.slide3.marker-text": "配置您的工作流程",
  "bot-builder.step1.slide4.text": "第四张幻灯片需要实际的文案",
  "bot-builder.step1.slide4.marker-text": "连接到您的APIs",
  "bot-builder.step2.label": "第二步",
  "bot-builder.step2.title": "培训 AI",
  "bot-builder.step2.ai-smarts.title": "添加 AI Smarts",
  "bot-builder.step2.ai-smarts.description": "通过关键字和自然语言处理为您的用户提供真实的对话体验。",
  "bot-builder.step2.user-voice.title": "给您的用户一个声音",
  "bot-builder.step2.user-voice.description": "启用语音模式或创建纯语音体验以满足用户需求并通过AI驱动的语音转文字功能增强可访问性。",
  "bot-builder.step3.label": "第三步",
  "bot-builder.step3.title": "发布",
  "bot-builder.step3.description1": "连接世界和您的业务",
  "bot-builder.step3.description2": "Stackchat 全渠道消息传递使您可以通过他们喜欢的渠道吸引用户，甚至可以跨渠道联系他们。",
}