import { faFacebookF, faGithub, faLinkedin, faTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "gatsby"
import * as React from "react"
import { FormattedMessage } from "react-intl"
import styled from "styled-components"
import logo from "../../assets/images/logo.svg"
import { docsLinkPrefix } from "../../config/link"
import { locale } from "../../config/locale"
import Image from "../image"

const FooterContainer = styled.footer`
  padding-top: 24px;
  padding-bottom: 16px;

  * {
    color: #fff;
  }

  @media only screen and (max-width: 575px) {
    h4 {
      font-size: 16px;
    }
  }
`

const Column = styled.div`
  margin-top: 26px;
`

const List = styled.ul`
  list-style-type: none;
  font-weight: 500;

  li {
    line-height: 32px;

    a {
      color: #ececec;

      &:hover {
        color: #ececec;
      }
    }
  }
`

const BottomList = styled.ul`
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  margin-top: 24px;

  li {
    margin-right: 16px;
    margin-top: 8px;
    font-weight: 500;
  }

  @media only screen and (max-width: 576px) {
    flex-wrap: wrap;
  }
`

const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  margin-left: 16px;
`

export default class Footer extends React.Component {
  getSocialMenu = () => {
    return (
      <>
        {locale === "zh" ? null : (
          <>
            <IconContainer className="d-flex align-items-center justify-content-center">
              <a href="https://twitter.com/stackchatapp" target="_blank">
                <FontAwesomeIcon icon={faTwitter} color="#fff" />
              </a>
            </IconContainer>

            <IconContainer className="d-flex align-items-center justify-content-center">
              <a href="https://www.facebook.com/stackchatapp/" target="_blank">
                <FontAwesomeIcon icon={faFacebookF} color="#fff" />
              </a>
            </IconContainer>
          </>
        )}

        <IconContainer className="d-flex align-items-center justify-content-center">
          <a href="https://www.linkedin.com/company/18146962" target="_blank">
            <FontAwesomeIcon icon={faLinkedin} color="#fff" />
          </a>
        </IconContainer>

        {locale === "zh" ? null : (
          <IconContainer className="d-flex align-items-center justify-content-center">
            <a href="https://www.youtube.com/channel/UCOEiMNOV74gJqrYq-7mXsMQ" target="_blank">
              <FontAwesomeIcon icon={faYoutube} color="#fff" />
            </a>
          </IconContainer>
        )}

        <IconContainer className="d-flex align-items-center justify-content-center">
          <a href="https://github.com/stackchat-ai/ConversationAIPlatform" target="_blank">
            <FontAwesomeIcon icon={faGithub} color="#fff" />
          </a>
        </IconContainer>
      </>
    )
  }

  render() {
    return (
      <FooterContainer className="container-fluid black">
        <div className="container">
          <div className="row">
            <Column className="col-6 col-md-3">
              <Link to="/"><Image src={logo} maxWidth="144px" /></Link>
            </Column>

            <Column className="col-6 d-flex flex-row align-items-top justify-content-end d-md-none">
              {this.getSocialMenu()}
            </Column>

            <Column className="col-4 col-md-2">
              <h4 style={{ textTransform: "uppercase" }}>
                <FormattedMessage id="footer.product.title" />
              </h4>
              <List>
                <li>
                  <Link to="/bot-builder">
                    <FormattedMessage id="footer.product.bot-builder" />
                  </Link>
                </li>
                <li>
                  <Link to="/pricing">
                    <FormattedMessage id="footer.product.pricing" />
                  </Link>
                </li>
                <li>
                  <Link to="/changelog">
                    <FormattedMessage id="footer.product.changelog" />
                  </Link>
                </li>
              </List>
            </Column>

            <Column className="col-4 col-md-2">
              <h4 style={{ textTransform: "uppercase" }}>
                <FormattedMessage id="footer.learn.title" />
              </h4>
              <List>
                <li>
                  <Link to="/blog">
                    <FormattedMessage id="footer.learn.blog" />
                  </Link>
                </li>
                <li>
                  <a href={docsLinkPrefix} target="_blank">
                    <FormattedMessage id="footer.learn.docs" />
                  </a>
                </li>
              </List>
            </Column>

            <Column className="col-4 col-md-2">
              <h4 style={{ textTransform: "uppercase" }}>
                <FormattedMessage id="footer.company.title" />
              </h4>
              <List>
                <li>
                  <Link to="/contact">
                    <FormattedMessage id="footer.company.contact" />
                  </Link>
                </li>
              </List>
            </Column>

            <Column className="d-none d-md-flex col-md-3 flex-row align-items-top justify-content-end">
              {this.getSocialMenu()}
            </Column>
          </div>
        </div>

        <div className="container d-flex align-items-center justify-content-start">
          <div className="row">
            <div className="col-12">
              <BottomList>
                {locale === "zh" ? (
                  <li>沪ICP备17002909号-3</li>
                ) :
                  null
                }

                <li>
                  <Link to="/terms">
                    <FormattedMessage id="footer.terms" />
                  </Link>
                </li>
                <li>
                  <Link to="/privacy">
                    <FormattedMessage id="footer.privacy" />
                  </Link>
                </li>
                <li>© {new Date().getFullYear()} Stackchat Pty Ltd.</li>
              </BottomList>
            </div>
          </div>
        </div>
      </FooterContainer>
    )
  }
}