import { faCheck, faCopy } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { N0, N100, N20, P500 } from '@stackchat/colors';
import Highlight, { defaultProps, Language } from 'prism-react-renderer';
import * as React from 'react';
import styled from 'styled-components';
import './prism-nord.css';

const InternalCodeContainer = styled.div`
  position: relative;
  display: block;
  width: 100%;
  margin-top: 1rem;
`;

const InternalPre = styled.pre`
  position: relative;
  padding: 8px 12px;
  border-radius: 2px;
  max-height: 48rem;
  overflow: auto;
`;

const InternalSpan = styled.span`
  font-family: 'ProjectFontMono';
  font-size: 0.9rem;
  line-height: 1.35rem;
`;

const LineNo = styled.span`
  display: inline-block;
  width: 2em;
  user-select: none;
  opacity: 0.3;
  font-family: 'ProjectFontMono';
  font-size: 1rem;
  line-height: 1.5rem;
`;

const CopyAction = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 32px;
  height: 32px;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${N0 };
  color: ${N100 };
  border-radius: 2px;
  cursor: pointer;

  &:hover {
    color: ${P500 };
  }
`;

function Pre(props: any) {
  const languageClassName = (props.children.props.className || '') as string;
  const language =
    languageClassName.length > 0 ? (languageClassName.replace('language-', '') as Language) : 'javascript';
  const code = props.children.props.children;

  const copyCodeToClipboard = () => {
    if (window) {
      const textarea = document.createElement('textarea');
      textarea.value = code;
      textarea.setAttribute('style', 'opacity:0;position:absolute;top:0;right:0');
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);

      setJustCopied(true);
      window.setTimeout(() => setJustCopied(false), 1800);
    }
  };

  const [justCopied, setJustCopied] = React.useState(false);

  return (
    <InternalCodeContainer>
      <Highlight {...defaultProps} code={code} language={language} theme={undefined}>
        {({ className, style, tokens, getLineProps, getTokenProps }) => (
          <InternalPre className={className} style={style}>
            {tokens.map((line, i) => (
              <div key={i} {...getLineProps({ line, key: i })}>
                <LineNo>{i + 1}</LineNo>
                {line.map((token, key) => (
                  <InternalSpan key={key} {...getTokenProps({ token, key })} />
                ))}
              </div>
            ))}
          </InternalPre>
        )}
      </Highlight>

      {/* Copy Action */}
      <CopyAction onClick={copyCodeToClipboard}>
        <FontAwesomeIcon icon={justCopied ? faCheck : faCopy} />
      </CopyAction>
    </InternalCodeContainer>
  );
}

const InternalCode = styled.code`
  font-size: 1rem;
  line-height: 1.8rem;
  padding: 0.2rem 0.4rem 0.3rem 0.5rem;
  background-color: ${N20 };
  font-family: 'ProjectFontMono';
  border-radius: 2px;

  @media only screen and (max-width: 575.99px) {
    font-size: 0.9rem;
    line-height: 1.62rem;
  }
`;

function Code(props: any) {
  return <InternalCode {...props} />;
}

const CodeElements = {
  pre: Pre,
  inlineCode: Code
};

export default CodeElements;
