import { faLinkedin, faTwitter } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import TextElements from "components/new-design-system/text-elements"
import * as React from "react"
import { AUTHORS } from "./authors"
import { AuthorAvatar, AuthorContainer, AuthorInfoContainer, LHSContent, RHSContent, SocialIcon } from "./styled"

const MONTHS = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
]

interface BlogAuthorProps {
  alias?: string
  date: string
}

export default function BlogAuthor(props: BlogAuthorProps) {
  if (!props.alias) {
    return null
  }

  const author = AUTHORS.find(
    a => a.alias === `@${ props.alias }`
  )

  if (!author) {
    return null
  }

  const { image, name, socials: { linkedin, twitter } } = author

  const date = new Date(props.date)
  const formattedDate = MONTHS[date.getMonth()]
    + ` `
    + date.getDate()
    + `, `
    + date.getFullYear()

  return (
    <AuthorContainer>
      <LHSContent>
        <AuthorAvatar
          style={{
            backgroundImage: `url("${ image }")`
          }}
        />

        <AuthorInfoContainer>
          <TextElements.p className="not-mobile">
            {`By ${ name } on ${ formattedDate }`}
          </TextElements.p>
          <TextElements.p className="mobile">
            {`By ${ name }`}
          </TextElements.p>
          <TextElements.p className="mobile date">
            {formattedDate}
          </TextElements.p>
        </AuthorInfoContainer>
      </LHSContent>

      <RHSContent>
        {
          linkedin
            ? (
              <TextElements.a target="_blank" href={linkedin}>
                <SocialIcon>
                  <FontAwesomeIcon icon={faLinkedin} />
                </SocialIcon>
              </TextElements.a>
            )
            : null
        }

        {
          twitter
            ? (
              <TextElements.a target="_blank" href={twitter} style={{ marginLeft: "0.5rem" }}>
                <SocialIcon>
                  <FontAwesomeIcon icon={faTwitter} />
                </SocialIcon>
              </TextElements.a>
            )
            : null
        }
      </RHSContent>
    </AuthorContainer>
  )
}