import { N40, P500 } from "@stackchat/colors"
import { Link } from "gatsby"
import styled from "styled-components"

export const Child = styled.div`
  flex: 0 0 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2rem;
`

export const ContactAndSubscribeContainer = styled.div`
  width: 100%;
  max-width: 65.75rem;
  margin: 4rem auto 0;
  padding: 3rem 1rem 5rem;
  display: flex;
  flex-direction: row-reverse;
  align-items: stretch;
  border-top: 1px solid ${ N40 };

  @media screen and (max-width: 575px) {
    flex-direction: column;
  }
`

export const FootnoteLink = styled(Link)`
  color: ${ P500 };

  &:hover {
    color: ${ P500 };
  }
`
