import * as React from "react"
import { Button as StyledButton } from "./styled"

interface ButtonProps {
  hollow?: boolean
  inverse?: boolean
  title: React.ReactNode
  onClick?: React.MouseEventHandler
  disabled?: boolean
  className?: string
  background?: string
}

export default class Button extends React.Component<ButtonProps> {
  getClasses = (): string => {
    const { hollow = false, inverse = false, background } = this.props
    const color = background || "P700"

    if (hollow) {
      if (inverse) {
        return "N0 transparent N0-border"
      }

      return `${color} transparent ${color}-border`
    }

    if (inverse) {
      return `${color} N0-bg N0-border`
    }

    return `N0 ${color}-bg P700-border`
  }

  render() {
    const { onClick, title, disabled = false, className = "" } = this.props
    const classes = this.getClasses()

    return (
      <StyledButton
        className={`${classes} ${className}`}
        disabled={disabled}
        onClick={onClick}
      >
        {title}
      </StyledButton>
    )
  }
}