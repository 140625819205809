import BlogAuthor from "components/blog-author"
import ContactAndSubscribe from "components/contact-and-subscribe"
import Hero from "components/hero"
import HubspotForm from "components/hubspot-form"
import LayoutElements from "components/new-design-system/layout-elements"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import * as React from "react"
import Layout from "templates/layout/newIndex"
import RelatedArticle from "templates/related-article/index"
import { BlogContent, KeepReadingTitle, RelatedArticles, RelatedArticlesCarousel } from "./styled"

interface BlogProps {
  data: {
    mdx: {
      body: string
      excerpt: string
      frontmatter: {
        title: string
        date: string
        hero?: string
        related?: string[]
        author?: string
        footnote?: string
      }
    }
  }
}

export default function Blog(props: BlogProps) {
  const { body, frontmatter: { title, hero, related, date, author, footnote } } = props.data.mdx

  return (
    <Layout title={title} alwaysOnHeader={true}>
      <LayoutElements.article>
        <Hero image={hero} title={title} />

        <BlogAuthor alias={author} date={date} />

        <BlogContent>
          <MDXRenderer>
            {body}
          </MDXRenderer>
        </BlogContent>


        {
          !!related && related.length > 0
            ? (
              <RelatedArticles>
                <KeepReadingTitle>Keep Reading:</KeepReadingTitle>

                <RelatedArticlesCarousel>
                  {
                    related.map((relatedArticle, key) => (
                      <RelatedArticle key={key} slug={relatedArticle} />
                    ))
                  }
                </RelatedArticlesCarousel>
              </RelatedArticles>
            )
            : null
        }
      </LayoutElements.article>

      <ContactAndSubscribe
        footnote={footnote}
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(frontmatter: {path: {eq: $slug}}) {
      id
      excerpt(pruneLength: 160)
      frontmatter {
        title
        hero
        date(formatString: "MMMM DD, YYYY")
        related
        author
        footnote
      }
      body
    }
  }
`