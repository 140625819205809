import { faAngleDown } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as React from "react"
import { FormattedMessage } from "react-intl"
import Button from "../button"
import { Item } from "./items"
import { ListItem, StyledExternalLink, StyledLink, SubList, SubMenu, SubMenuContainer } from "./styled"

interface SubLevelItemProps {
  item: Item
  onFinish(): void
}

interface SubLevelItemState {
  isVisible: boolean
}

export default class SubLevelItem extends React.Component<SubLevelItemProps, SubLevelItemState> {
  state: SubLevelItemState = {
    isVisible: false
  }

  toggleSubMenu = () => this.setState(prevState => ({
    isVisible: !prevState.isVisible
  }))

  getLinkContent = () => {
    const { item } = this.props
    const { appearance, items, title, hollow, inverse } = item

    if (appearance === "text") {
      return (
        <>
          <FormattedMessage id={title} />
          {items && items.length > 0 ? <FontAwesomeIcon icon={faAngleDown} /> : null}
        </>
      )
    }

    return (
      <Button
        hollow={hollow}
        title={<FormattedMessage id={title} />}
        inverse={inverse}
      />
    )
  }

  getLink = () => {
    const { item, onFinish } = this.props

    if (item.items && item.items.length > 0) {
      return (
        <StyledLink to="">
          {this.getLinkContent()}
        </StyledLink>
      )
    }

    if (item.link.indexOf("http") === 0) {
      return (
        <StyledExternalLink href={item.link} target="_blank" onClick={onFinish}>
          {this.getLinkContent()}
        </StyledExternalLink>
      )
    }

    return (
      <StyledLink to={item.link} onClick={onFinish}>
        {this.getLinkContent()}
      </StyledLink>
    )
  }

  getSubItems = () => {
    const { items } = this.props.item

    if (!items || items.length === 0) {
      return null
    }

    return (
      <SubList>
        {items.map((item, index) => {
          return <SubLevelItem key={index} item={item} />
        })}
      </SubList>
    )
  }

  render() {
    const { isVisible } = this.state

    return (
      <ListItem onClick={this.toggleSubMenu}>
        {this.getLink()}

        {isVisible ? this.getSubItems() : null}
      </ListItem>
    )
  }
}