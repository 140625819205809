import styled from "styled-components"

export const Header = styled.header`
  margin-bottom: 0;
  width: 100%;
  max-width: 65.75rem;
  margin: 6rem 0;
  text-align: center;

  @media only screen and (max-width: 575.99px) {
    margin-bottom: 2rem;
  }
`

export const HeroWrapper = styled.div`
  margin-top: 3rem;
  margin-bottom: 2rem;

  @media only screen and (max-width: 575.99px) {
    margin-top: 2rem;
  }
`

export const ContentWrapper = styled.div`
  width: 100%;
  max-width: 65.75rem;
  margin: 0 auto;
  text-align: center;
`

export const Image = styled.div`
  position: relative;
  width: 100%;
  height: 40rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 23px 40px rgba(0, 0, 0, 0.2);
  background-size: cover;
  background-position: 50% 50%;

  @media only screen and (max-width: 575.99px) {
    height: 18rem;
  }
`
