import Header from "components/header"
import { initialiseMessenger } from "components/messenger"
import LayoutElements from "components/new-design-system/layout-elements"
import { locale, Locale } from "config/locale"
import * as React from "react"
import Helmet from "react-helmet"
import { GlobalColors } from "./colors"
import "./index.scss"
import { setup } from "./setup"

interface LayoutProps {
  alwaysOnHeader?: boolean,
  className?: string
  title: string
  children?: React.ReactNode
}

function Layout(props: LayoutProps) {
  React.useEffect(() => {
    if (typeof window !== `undefined` && locale !== Locale.Chinese) {
      initialiseMessenger()
    }
  }, [])


  return (
    <>
      <Helmet title={props.title} />

      <GlobalColors />

      <Header alwaysOnBackground={props.alwaysOnHeader || false} />

      <LayoutElements.main className={`container-fluid ${ props.className }`}>
        {props.children}
      </LayoutElements.main>
    </>
  )
}

export default setup(Layout)