import { docsLinkPrefix, studioLinkPrefix } from "../../config/link"
import { locale } from "../../config/locale"

// tslint:disable:object-literal-sort-keys

export interface Item {
  /** Type of item */
  appearance: "button" | "text"
  /** The text to be displayed */
  title: string
  /** The slug at which this item is located */
  link: string
  /**
   * If the menu item has a sub menu
   */
  items?: Item[]
  /**
   * To be used in conjugation with appearance
   * "button"
   *
   * Decides whether or not the button has
   * solid fill or not
   */
  hollow?: boolean
  inverse?: boolean
  /**
   * Determines whether the item is a top
   * level item or an item in the sub menu
   */
  topLevel?: boolean
}


export const ITEMS: Item[] = [
  // {
  //   appearance: "text",
  //   title: "Platform",
  //   link: "platform",
  //   topLevel: true,
  //   items: [
  //     {
  //       appearance: "text",
  //       title: "Messaging Channels",
  //       link: "messaging-channels",
  //       items: [
  //         {
  //           appearance: "text",
  //           title: "SDKs",
  //           link: "available-sdks"
  //         },
  //         {
  //           appearance: "text",
  //           title: "Customer Profiles",
  //           link: "centralised-customer-profiles"
  //         }
  //       ]
  //     },
  //     {
  //       appearance: "text",
  //       title: "Conversation Builder",
  //       link: "conversation-builder",
  //       items: [
  //         {
  //           appearance: "text",
  //           title: "Bot Analytics",
  //           link: "bot-analytics"
  //         },
  //         {
  //           appearance: "text",
  //           title: "Blending AI & Agents",
  //           link: "blending-ai-and-agents"
  //         },
  //         {
  //           appearance: "text",
  //           title: "Adobe Marketing Cloud",
  //           link: "adobe-marketing-cloud"
  //         }
  //       ]
  //     },
  //     {
  //       appearance: "text",
  //       title: "Conversational AI",
  //       link: "conversational-ai",
  //       items: [
  //         {
  //           appearance: "text",
  //           title: "Voice AI",
  //           link: "voice-ai"
  //         },
  //         {
  //           appearance: "text",
  //           title: "Conversation Context",
  //           link: "ai-conversation-context"
  //         }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   appearance: "text",
  //   title: "Solutions",
  //   link: "solutions",
  //   topLevel: true,
  //   items: [
  //     {
  //       appearance: "text",
  //       title: "By Industry",
  //       link: "by-industry",
  //       items: [
  //         {
  //           appearance: "text",
  //           title: "Retail",
  //           link: "retail"
  //         },
  //         {
  //           appearance: "text",
  //           title: "Fintech",
  //           link: "fintech"
  //         },
  //         {
  //           appearance: "text",
  //           title: "Travel",
  //           link: "travel"
  //         }
  //       ]
  //     },
  //     {
  //       appearance: "text",
  //       title: "By Role",
  //       link: "by-role",
  //       items: [
  //         {
  //           appearance: "text",
  //           title: "Marketing",
  //           link: "marketing"
  //         },
  //         {
  //           appearance: "text",
  //           title: "Sales",
  //           link: "sales"
  //         },
  //         {
  //           appearance: "text",
  //           title: "Customer Care",
  //           link: "customer-care"
  //         }
  //       ]
  //     },
  //     {
  //       appearance: "text",
  //       title: "Conversational Marketing",
  //       link: "conversational-marketing"
  //     },
  //     {
  //       appearance: "text",
  //       title: "Consulting Services",
  //       link: "consulting-services"
  //     }
  //   ]
  // },
  {
    appearance: "text",
    title: "header.platform.title",
    link: "/bot-builder",
    topLevel: true
  },
  {
    appearance: "text",
    title: "header.resources.title",
    link: "resources",
    topLevel: true,
    items: [
      {
        appearance: "text",
        title: "header.resources.items.item1",
        link: docsLinkPrefix
      },
      {
        appearance: "text",
        title: "header.resources.items.item2",
        link: "/blog"
      }
    ]
  },
  {
    appearance: "text",
    title: "header.pricing.title",
    link: "/pricing",
    topLevel: true
  },
  {
    appearance: "text",
    title: "header.enterprise.title",
    link: "/enterprise",
    topLevel: true
  },
  {
    appearance: "button",
    title: "header.demo.title",
    link: "/contact",
    topLevel: true,
    hollow: false,
    inverse: true
  },
  {
    appearance: "button",
    title: "header.signup.title",
    link: `${ studioLinkPrefix + (locale === "zh" ? "" : "/account/register")}`,
    hollow: true,
    topLevel: true,
    inverse: true
  },
  {
    appearance: "text",
    title: "header.signin.title",
    link: `${ studioLinkPrefix + (locale === "zh" ? "" : "/account/login")}`,
    topLevel: true,
  }
]