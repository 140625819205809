import { ProactiveMessaging } from "@stackchat/proactive-messaging"
import "@stackchat/proactive-messaging/dist/main.css"
import Stackchat, { MessageUnion } from "@stackchat/web-messenger"
import { navigate } from "gatsby"
import throttle from "lodash/throttle"
import avatar from "../../assets/svg/stackbot-avatar.svg"

const MESSENGER_CONFIG = {
  appId: "hi1r987gf5qv8x",
  region: "au",
  soundNotificationEnabled: false,
  voiceOnly: false,
  businessName: "👋 Hi, I'm Stackley.",
  businessIconUrl: avatar,
  buttonIconUrl: avatar,
  menuItems: {
    imageUpload: false,
    fileUpload: false,
    shareLocation: false
  },
  customColors: {
    actionColor: "4c0099",
    brandColor: "4c0099",
    conversationColor: "4c0099"
  },
  voice: {
    // @ts-ignore
    key: "385055c91f514e23bba762439642f080",
    voiceOnly: false,
    locale: "en-AU"
  },
  customText: {
    headerText: "Stackchat",
    introductionText:
      "Say hi below to start chatting.",
  }
}

let messageStack: ProactiveMessaging

const setupDelegates = () => {
  Stackchat.setDelegate({
    // @ts-ignore
    beforeDisplay: (message) => {
      // @ts-ignore
      if (message.source && message.source.type === "sc-bot") {
        message.avatarUrl = avatar
      }

      if (message.metadata && message.metadata.hide === true) {
        return ""
      }


      return message
    },
    beforeSend: (messagePayload) => {
      return messagePayload.payload
    },

    // @ts-ignore
    beforePostbackSend: (postback) => {
      let route
      try {
        route = JSON.parse(
          postback.postback.payload.split("?")[1].split("=")[1]
        ).data.route

        console.log(`Navigating to: ${ route }`)

        if (route.startsWith("http")) {
          window.location.href = route
        } else {
          navigate(`/${ route }`)
        }

        return null
      } catch (error) {
        return postback
      }
    }
  })
}


let triggerfired: boolean = false
const triggerHandler = () => {
  if (!Stackchat.isOpened) {
    return
  }

  const message: MessageUnion = {
    text: "trigger",
    type: "text",
    metadata: {
      hide: true,
      src_pathname: document.location.pathname
    }
  }
  if (!Stackchat.isOpened() && !triggerfired) {
    Stackchat.sendMessage(message)
    // no more events from here until page reloads
    triggerfired = true
  }
}

const throttledTrigger = throttle(triggerHandler, 1000)

/* tslint:disable */
const setUpTrigger = () => {
  var checkIfScrollBottom = function () {
    // var CheckIfScrollBottom = function () {
    if (getDocHeight() == getScrollXY()[1] + window.innerHeight) {

      throttledTrigger();

    }
  };

  document.addEventListener('scroll', checkIfScrollBottom);
  triggerfired = false;
  function getScrollXY() { var a = 0, b = 0; return "number" == typeof window.pageYOffset ? (b = window.pageYOffset, a = window.pageXOffset) : document.body && (document.body.scrollLeft || document.body.scrollTop) ? (b = document.body.scrollTop, a = document.body.scrollLeft) : document.documentElement && (document.documentElement.scrollLeft || document.documentElement.scrollTop) && (b = document.documentElement.scrollTop, a = document.documentElement.scrollLeft), [a, b] }
  function getDocHeight() { var a = document; return Math.max(a.body.scrollHeight, a.documentElement.scrollHeight, a.body.offsetHeight, a.documentElement.offsetHeight, a.body.clientHeight, a.documentElement.clientHeight) }

}
/* tslint:enable */


let stackchatInitilisedFlag: boolean = false
export const initialiseMessenger = () => {
  if (stackchatInitilisedFlag) {
    return
  } else {
    stackchatInitilisedFlag = true
  }
  Stackchat.destroy()
  Stackchat.on("ready", () => {
    // Show starting whisper
    const conversation = Stackchat.getConversation()
    if (conversation.messages.length === 0) {
      Stackchat.simulateMessage("👋 Howdy! Can I help you with anything?", avatar)
      messageStack.addMessage(
        "👋 Howdy! Can I help you with anything?",
        [
          {
            text: "Hello",
            openMessengerOnReply: true
          }
        ]
      )
    }
  })

  Stackchat
    .init(MESSENGER_CONFIG)
    .then(() => {
      stackchatInitilisedFlag = true
      messageStack = new ProactiveMessaging({
        author: "Stackley",
        maxMessages: 4,
        ttl: 1000000,
        messenger: Stackchat
      })

      // Start proactive messaging
      messageStack.start()

      // Ensure activity indicator shows when user sends a message
      Stackchat.on("message:sent", () => Stackchat.showActivityIndicator(avatar))



      // setup delegates
      setupDelegates()
    })

  // setup trigger should happen even if init fails
  setUpTrigger()
}