import { Link } from "gatsby"
import styled, { css } from "styled-components"

export const Container = styled.header`
  position: fixed;
  top: 0;
  z-index: 10;
  background-color: transparent;
  transition: all 250ms linear;
  padding-left: 0;
  padding-right: 0;

  &.scrolled {
    background-color: #4c0099;
  }
`

export const MenuContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media only screen and (max-width: 767px) {
    z-index: 2;
    position: fixed;
    top: 56px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 8px;
    padding: 16px;

    &:after {
      content: "";
      position: absolute;
      top: -2px;
      right: 12px;
      width: 22px;
      height: 22px;
      transform: rotate(45deg);
      border-left: 12px solid #fff;
      border-top: 12px solid #fff;
    }
  }
`

export const HomeLink = styled(Link)`
  width: 100%;
`

export const StyledDiv = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  line-height: 22px;

  &:hover {
    text-decoration: none;
    opacity: 0.72;
  }
`

export const StyledLink = styled(Link)`
  display: flex;
  height: 100%;
  align-items: center;
  line-height: 22px;
  transition: all 100ms linear;

  &:hover {
    text-decoration: none;
    opacity: 0.72;
  }
`

export const StyledExternalLink = styled.a`
  display: flex;
  height: 100%;
  align-items: center;
  line-height: 22px;
  transition: all 100ms linear;

  &:hover {
    text-decoration: none;
    opacity: 0.72;
  }
`

export const ListItem = styled.li`
  position: relative;
  cursor: pointer;
  min-height: 40px;
  padding-left: 24px;
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  display: flex;
  align-items: center;
  flex-direction: row;
  font-weight: 900;

  ${StyledLink }, ${ StyledExternalLink } {
    color: #fff;
    width: 100%;
  }

  svg {
    margin-left: 4px;
    height: 11px;
  }

  @media only screen and (max-width: 992px) {
    font-size: 12px;
    padding-left: 16px;
  }

  @media only screen and (max-width: 767px) {
    color: #4c0099;
    padding-right: 16px;
    height: auto;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-size: 20px;
    min-height: 40px;
    margin-top: 16px;

    ${StyledLink }, ${ StyledExternalLink } {
      color: #4c0099;
    }

    button {
      width: 100%;
    }
  }
`

export const List = styled.ul`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  list-style-type: none;
  flex-direction: row;
  margin: 0;

  ${ListItem } {
    &:last-of-type {
      font-weight: 500;
    }
  }

  @media only screen and (max-width: 767px) {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    ${ListItem } {
      width: 100%;
      
      &:last-of-type {
        font-weight: 900;
      }
    }
  }
`

export const SubList = styled.ul`
  width: 100%;
  padding: 0 16px;
  /* padding: 16px; */
  border-radius: 8px;
  ${(props: { hollow?: boolean }) => css`
    background-color: ${props.hollow ? "transparent" : "#efefef" };
  `}

  ${ListItem } {
    color: #252525;
    padding-left: 0;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 9px;
    margin-top: 0;

    &:last-of-type {
      font-weight: 900;
    }

    ${StyledLink }, ${ StyledExternalLink } {
      color: #252525;
    }

    ul {
      padding: 16px;
    }
  }
`

export const SubMenuContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 60%;

  @media only screen and (max-width: 767px) {
    position: relative;
    top: auto;
    left: auto;
    width: 100%;
  }
`

const RoundedContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 8px;
  padding-top: 8px;
  box-shadow: 0 12px 12px 0px #d8d8d8;
  position: relative;
`

export const SubMenu = styled(RoundedContainer)`
  position: relative;
  height: auto;
  box-shadow: 0px 2px 4px -2px #4a4a4a;
  left: -50%;
  width: auto;
  white-space: nowrap;

  &:after {
    content: "";
    position: absolute;
    top: -2px;
    right: calc(50% - 8px);
    width: 22px;
    height: 22px;
    transform: rotate(45deg);
    border-left: 12px solid #fff;
    border-top: 12px solid #fff;
  }

  @media only screen and (max-width: 767px) {
    left: auto;
    box-shadow: none;
    background-color: #efefef;
    width: 100%;

    &:after {
      content: none;
    }
  }
`

export const MobileMenuContainer = styled.div`
  position: fixed;
  overflow: hidden;
  top: 48px;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 16px 8px 8px;
  z-index: 12;
  display: none !important;

  &.show {
    display: flex !important;
  }
`

export const IconContainer = styled.div`
  height: 32px;
  width: 32px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`