import { N400, N60, P500 } from "@stackchat/colors"
import styled from "styled-components"

export const AuthorContainer = styled.div`
  margin: 0 auto 6rem;
  max-width: 65.75rem;
  width: 100%;
  border-bottom: 1px solid #e2e2e2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;

  @media only screen and (max-width: 575.99px) {
    margin-bottom: 0rem;
  }
`

export const LHSContent = styled.div`
  display: flex;
  align-items: center;
  width: calc(100% - 4.5rem);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`

export const AuthorInfoContainer = styled.div`
  flex-grow: 1;
  max-width: calc(100% - 2.5rem);
  display: flex;
  flex-direction: column;

  p {
    text-align: left;
    font-size: 1rem;
    line-height: 1.5rem;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0;

    &.not-mobile {
      display: block;
    }

    &.mobile {
      display: none;
    }

    &.date {
      font-size: 0.8rem;
      line-height: 1.2rem;
      color: ${ N400 };
    }
  }

  @media only screen and (max-width: 575.99px) {
    p {
      &.mobile {
        display: block;
      }

      &.not-mobile {
        display: none;
      }
    }
  }
`

export const RHSContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 4.5rem;
`

export const AuthorAvatar = styled.div`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-size: cover;
  background-position: 50% 50%;
  margin-right: 0.5rem;
  flex-shrink: 0;
  border: 1px solid ${ N60 };

  @media only screen and (max-width: 575.99px) {
    width: 3rem;
    height: 3rem;
  }
`

export const SocialIcon = styled.div`
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${ N60 };
  font-size: 1.5rem;
  cursor: pointer;
  flex-shrink: 0;

  &:hover {
    color: ${ P500 };
  }
`