import { MDXProviderProps } from '@mdx-js/react';
import CodeElements from './code-elements';
import LayoutElements from './layout-elements';
import TextElements from './text-elements';

const DesignElements: MDXProviderProps['components'] = {
  ...TextElements,
  ...LayoutElements,
  ...CodeElements
};

export default DesignElements;
