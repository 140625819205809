import { locale, Locale } from "config/locale"
import * as React from "react"
import { FormContainer, getStyles } from "./styled"

interface HubspotFormProps {
  light?: boolean
  portalId: string
  formId: string
  id?: string
  padded?: boolean
  color?: string
}


export default class HubspotForm extends React.Component<HubspotFormProps> {
  private iframeCheckCount: number = 0

  componentDidMount() {
    /* tslint:disable */
    if (window) {
      // @ts-ignore
      window.jQuery = window.jQuery || (() => ({
        // these are all methods required by HubSpot
        change: () => { },
        trigger: () => { },
      }))
    }
    /* tslint:enable */

    const { portalId, formId, id } = this.props

    if (portalId && formId) {
      if ((window as any).hbspt) {
        (window as any).hbspt.forms.create({
          formId,
          portalId,
          target: `#form-${ id || portalId }`,
          onFormReady: this.applyCustomStyles
        })
      }
    }
  }

  applyCustomStyles = () => {
    // increment check counter.
    // we will check a max of 3 times
    this.iframeCheckCount++

    if (this.iframeCheckCount > 3) {
      return
    }

    const elements = window.document.getElementsByClassName("hs-form-iframe")

    if (elements.length === 0) {
      window.setTimeout(this.applyCustomStyles, 300)
    } else {
      this.setCustomStyles()
    }
  }

  setCustomStyles = () => {
    const elements = window.document.getElementsByClassName("hs-form-iframe")
    const iframe = elements[0] as HTMLIFrameElement

    let document

    if (iframe.contentDocument) {
      document = iframe.contentDocument
    } else if (iframe.contentWindow && iframe.contentWindow.document) {
      document = iframe.contentWindow.document
    }

    if (!document) {
      return
    }

    const fontStyles = document.createElement("link")
    // <link rel="stylesheet" media="screen, projection" href="assets/css/pik5.css">
    fontStyles.rel = "stylesheet";
    fontStyles.type = "text/css";
    fontStyles.href = `/fonts/font-styles-${ locale === Locale.Chinese ? "zh" : "au" }.css`

    const formStyles = document.createElement("style")
    formStyles.type = "text/css"
    const { light = true, color } = this.props
    formStyles.innerText = getStyles(light, color)

    document.head.appendChild(fontStyles)
    document.head.appendChild(formStyles)
    this.iframeCheckCount = 0
  }

  render() {
    const { portalId, id, padded = false } = this.props

    return (
      <FormContainer id={`form-${ id || portalId }`} padded={padded} />
    )
  }
}