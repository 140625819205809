import * as React from "react"
import styled, { css } from "styled-components"

const StyledImage = styled.img`
  width: 100%;
  height: auto;
  ${(props: ImageProps) => css`
    max-width: ${props.maxWidth};
    max-height: ${props.maxHeight};
    border-radius: ${props.showShadow ? "6px" : "0"};
    box-shadow: ${props.showShadow ? "0 2px 4px 2px rgba(0,0,0,0.2)" : "none"};
  `}
`

interface ImageProps {
  src: any
  srcSet?: string
  maxWidth?: string
  maxHeight?: string
  showShadow?: boolean
  classes?: string
}

export default class Image extends React.Component<ImageProps> {
  render() {
    const {
      src,
      srcSet,
      maxWidth = "100%",
      maxHeight = "auto",
      showShadow = false,
      classes = ""
    } = this.props

    return (
      <StyledImage
        className={classes}
        src={src}
        srcSet={srcSet}
        maxWidth={maxWidth}
        maxHeight={maxHeight}
        showShadow={showShadow}
      />
    )
  }
}