import styled from "styled-components"

export const Button = styled.button`
  
  font-weight: 900;
  font-size: 14px;
  line-height: 16px;
  padding: 6px 8px;
  border-radius: 4px;
  text-transform: uppercase;
  border-width: 2px;
  border-style: solid;

  &.large {
    font-size: 20px;
    padding: 12px 15px;
    border-radius: 8px;
    min-width: 160px;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &:focus, &:active {
    outline: none;
  }

  @media only screen and (max-width: 992px) {
    font-size: 12px;
  }

  @media only screen and (max-width: 575.98px) {
    width: 100%;
  }
`