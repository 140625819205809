import { N0, N60, N800, P500 } from "@stackchat/colors"
import { Link } from "gatsby"
import { kebabCase } from "lodash"
import * as React from "react"
import styled, { css } from "styled-components"

/* get Element id */
const getElementId = (title: React.ReactNode) => {
  if (
    title
    && typeof title === "string"
  ) {
    return kebabCase(title).substr(0, 40)
  }

  return undefined
}

/* H1 */
const InternalH1 = styled.h1`
  font-size: 6rem;
  line-height: 6rem;
  letter-spacing: -0.09375rem;
  margin: 0;
  padding: 0;
  color: ${ N800 };
`

function H1(props: any) {
  return (
    <InternalH1
      id={getElementId(props.children)}
      {...props}
    />
  )
}

/* H2 */
const InternalH2 = styled.h2`
  font-size: 3.75rem;
  line-height: 3.75rem;
  letter-spacing: -0.03125rem;
  margin: 0;
  margin-top: 2.5rem;
  padding: 0;
  color: ${ N800 };

  @media only screen and (max-width: 575.99px) {
    font-size: 2.5rem;
    line-height: 2.5rem;
    margin-top: 1.675rem;
  }
`

function H2(props: any) {
  return (
    <InternalH2
      id={getElementId(props.children)}
      {...props}
    />
  )
}

/* H3 */
const InternalH3 = styled.h3`
  font-size: 3rem;
  line-height: 3.125rem;
  letter-spacing: 0;
  margin: 0;
  margin-top: 4rem;
  padding: 0;
  color: ${ N800 };

  + p {
    margin-top: 1rem;
  }

  @media only screen and (max-width: 575.99px) {
    font-size: 2rem;
    line-height:  2.08rem;
    margin-top: 2.68rem; 
  }
`

function H3(props: any) {
  return (
    <InternalH3
      id={getElementId(props.children)}
      {...props}
    />
  )
}

/* H4 */
const InternalH4 = styled.h4`
  font-size: 2rem;
  line-height: 2.5rem;
  letter-spacing: 0.015625rem;
  margin: 0;
  margin-top: 2.5rem;
  padding: 0;
  color: ${ N800 };

  + p {
    margin-top: 1rem;
  }

  @media only screen and (max-width: 575.99px) {
    font-size: 1.5rem;
    line-height: 1.875rem;
    margin-top: 1.875rem;
  }
`

function H4(props: any) {
  return (
    <InternalH4
      id={getElementId(props.children)}
      {...props}
    />
  )
}

/* H5 */
const InternalH5 = styled.h5`
  font-size: 1.5rem;
  line-height: 2rem;
  letter-spacing: 0;
  margin: 0;
  margin-top: 1.5rem;
  padding: 0;
  color: ${ N800 };

  + p {
    margin-top: 0.5rem;
  }

  @media only screen and (max-width: 575.99px) {
    font-size: 1rem;
    line-height:  1.34rem;
    margin-top: 1rem; 
  }
`

function H5(props: any) {
  return (
    <InternalH5
      id={getElementId(props.children)}
      {...props}
    />
  )
}

/* H6 */
const InternalH6 = styled.h6`
  font-size: 1.25rem;
  line-height: 2rem;
  letter-spacing: 0.009375rem;
  margin: 0;
  margin-top: 1rem;
  padding: 0;
  color: ${ N800 };

  + p {
    margin-top: 1rem;
  }

  @media only screen and (max-width: 575.99px) {
    font-size: 0.84rem;
    line-height:  1.34rem;
    margin-top: 0.67rem;
  }
`

function H6(props: any) {
  return (
    <InternalH6
      id={getElementId(props.children)}
      {...props}
    />
  )
}

// P
const P = styled.p`
  font-size: 1.2rem;
  line-height: 1.8rem;
  letter-spacing: 0.03125rem;
  text-align: justify;
  margin-top: 2rem;
  padding: 0;
  color: ${ N800 };

  &.large {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }

  &.inverse {
    color: ${ N0 };
  }

  @media only screen and (max-width: 575.99px) {
    font-size: 1.125rem;
    line-height: 1.6875rem;
    margin-top: 1.5rem;
    text-align: left;

    &.large {
      font-size: 1.40625rem;
      line-height: 2.109375rem;
    } 
  }
`

// BlockQuote
const BlockQuote = styled.blockquote`
  margin: 0;
  padding: 0;
  padding-left: 1rem;
  border-left: 0.25rem solid ${ N60 };

  ${ P } {
    font-size: 1.772rem;
    line-height: 2rem;
    color: ${ N60 };
    text-align: left;
    padding: 0.5rem 0;
    font-style: italic;
  }
`

// Link
const linkProps = () => css`
  display: inline-block;
  font-size: 1.2rem;
  line-height: 1.8rem;
  margin: 0;
  padding: 0;
  color: ${ P500 };
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    color: ${ P500 };
  }

  @media only screen and (max-width: 575.99px) {
    font-size: 1.125rem;
    line-height: 1.6875rem;
  }
`
const ExternalA = styled.a`${ linkProps }`

const InternalA = styled(Link)`${ linkProps }`

function A(props: any) {
  const { href, ...remainingProps } = props

  if (
    href.indexOf("https://") === 0
    || href.indexOf("http://") === 0
  ) {
    return (
      <ExternalA
        href={href}
        {...remainingProps}
        target="_blank"
      />
    )
  }

  return (
    <InternalA to={href} {...remainingProps} />
  )
}

// List
const listProps = () => css`
  margin: 0;
  margin-top: 1rem;
  padding: 0;
  padding-left: 2.4rem;

  @media only screen and (max-width: 575.99px) {
    padding-left: 1rem;
  }
`

const OrderedList = styled.ol`${ listProps }`

const UnorderedList = styled.ul`${ listProps }`

const ListItem = styled.li`
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: ${ N800 };

  ${ P } {
    margin-top: 1rem;
  }
  
  ${ P }:nth-child(2) {
    margin-top: 0;
  }

  @media only screen and (max-width: 575.99px) {
    font-size: 1.125rem;
    line-height: 1.6875rem; 
  }
`


const TextElements = {
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  h5: H5,
  h6: H6,
  p: P,
  blockquote: BlockQuote,
  a: A,
  li: ListItem,
  ul: UnorderedList,
  ol: OrderedList
}

export default TextElements